import moment from 'moment';
import qs from 'qs';

import { parseGender, parseGenderValue } from 'packages/http_client/utils/queryParamsHelpers/parsers';
import { formatUrl } from 'packages/http_client/utils/converters';
import { formatDateDashYMDTimeHMS } from 'components/utils/constants/formatters';
import { defaultMember, defaultAngelInvestorMember, defaultTechnicalLicenseMember } from 'redux/utils/defaultStates/MemberDefaultState';
import { identity, stringToArray } from 'utils';
import { LabeledValue } from 'types';
import {
  AddServiceProviderMemberPayload,
  CreateHub71EmployeePayload,
  CreateHub71EmployeeRequestPayload,
  CreateTeamMemberPayload,
  CreateTeamMemberRequestPayload,
  EditMemberApiType,
  GetInvolvementTypesApiResponseType,
  GetTeamMembersParams,
  InvolvementResponseType,
  InvolvementType,
  MemberApiType,
  AngelInvestorMemberApiType,
  ServiceProviderMemberType,
  TeamMember,
  AngelInvestorMember,
  UpdateTeamMemberRequestPayload,
  EditAngelInvestorMemberApiType,
  TechnicalLicenseMemberApiType,
  TechnicalLicenseMember,
  EditTechnicalLicenseMemberApiType,
} from '../types';
import {
  contains, GetResourceSuccessResponseType, HttpRequestConfig, queryBuilder,
} from '../../http_client';
import { AddPartnerMemberPayload, PartnerMemberType } from '../types/partner_member_type';

export const convertCreateTeamMemberRequestPayload = (payload: CreateTeamMemberPayload):
CreateTeamMemberRequestPayload => ({
  _active: '1',
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _email: payload.email,
  _job_title: payload.jobTitle,
  _startup_id: payload.startupId,
  _mobile_no: payload.phoneNumber,
  _gender: parseGenderValue(payload.gender),
  _founder: payload.founder,
  _headshot: payload.headshot,
  _hobbies: payload.hobbies,
  _responsibilities: payload.jobFunctions,
  _involvement_id: payload.involvementId,
  _skills: payload.skills,
  _bio: payload.bio,
  _date_of_birth: payload.dateOfBirth,
  _number_of_kids: payload.numberOfKids,
  _visa: payload.visa,
  _work_permit_name: payload.workPermitName,
  _emirates: payload.emirates,
  _employment_contract: payload.employmentContract,
  _passport: payload.passport,
  _nationality_id: payload.nationalityId,
  _whatsapp_no: payload.whatsAppNumber,
});

export const convertCreateTechnicalLicenseTeamMemberRequestPayload = (payload: CreateTeamMemberPayload):
CreateTeamMemberRequestPayload => ({
  _active: '1',
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _email: payload.email,
  _job_title: payload.jobTitle,
  _angel_investor_id: payload.angelInvestorId,
  _mobile_no: payload.phoneNumber,
  _gender: parseGenderValue(payload.gender),
  _headshot: payload.headshot,
  _involvement_id: payload.involvementId,
  _bio: payload.bio,
  _date_of_birth: payload.dateOfBirth,
  _number_of_kids: payload.numberOfKids,
  _visa: payload.visa,
  _work_permit_name: payload.workPermitName,
  _emirates: payload.emirates,
  _employment_contract: payload.employmentContract,
  _passport: payload.passport,
  _nationality_id: payload.nationalityId,
});

export const convertCreateAngelInvestorTeamMemberRequestPayload = (payload: CreateTeamMemberPayload):
CreateTeamMemberRequestPayload => ({
  _active: '1',
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _email: payload.email,
  _job_title: payload.jobTitle,
  _angel_investor_id: payload.angelInvestorId,
  _mobile_no: payload.phoneNumber,
  _gender: parseGenderValue(payload.gender),
  _headshot: payload.headshot,
  _involvement_id: payload.involvementId,
  _bio: payload.bio,
  _date_of_birth: payload.dateOfBirth,
  _number_of_kids: payload.numberOfKids,
  _visa: payload.visa,
  _work_permit_name: payload.workPermitName,
  _emirates: payload.emirates,
  _employment_contract: payload.employmentContract,
  _passport: payload.passport,
  _nationality_id: payload.nationalityId,
});

export const convertCreateHub71EmployeeRequestPayload = (payload: CreateHub71EmployeePayload):
CreateHub71EmployeeRequestPayload => ({
  _active: '1',
  _email: payload.email,
  _first_name: payload.firstName,
  _hub71_role: payload.jobTitle,
  _last_name: payload.lastName,
  _logo: payload.headshot,
  _designation: payload.designation,
  _emp_code: payload.employeeCode,
});

export const convertServiceProviderMemberRequestPayload = (
  serviceProviderId: number,
  payload: ServiceProviderMemberType,
): AddServiceProviderMemberPayload => ({
  _active: '1',
  _email: payload.email,
  _first_name: payload.firstName || payload.name,
  _job_title: typeof payload.role === 'string' ? payload.role : payload.role?.join(', '),
  _last_name: payload.lastName,
  _mobile: payload.phoneNumber,
  _phone: payload.phoneNumber,
  _service_provider_id: serviceProviderId,
});

export const convertGetTeamMemberRequestPayload = (payload?: GetResourceSuccessResponseType<TeamMember>)
  : MemberApiType => {
  if (!identity.isObjWithChildren(payload?.result)) {
    return defaultMember;
  }

  const { result } = payload!;

  return ({
    firstName: result!.firstName,
    lastName: result!.lastName,
    email: result!.email,
    dateOfBirth: identity.isFalsy(result!.dateOfBirth) ? '' : moment(result!.dateOfBirth).format(formatDateDashYMDTimeHMS),
    phoneNumber: result!.mobileNo,
    id: result!.id,
    location: {
      city: 'Dubai',
      country: 'UAE',
    },
    role: result!.jobTitle,
    gender: parseGender(result!.gender),
    nationality: result!.nationality.name,
    about: result!.bio,
    imageLink: result!.headshot,
    company: result!.startup.name,
    founder: result!.founder,
    skills: stringToArray(result!.skills),
    jobFunctions: stringToArray(result!.responsibilities),
    hobbies: stringToArray(result!.hobbies),
    startupId: result!.startupID,
    numberOfKids: result!.numberOfKids,
    nationalityId: result!.nationality.id,
    emiratesId: result!.emirates,
    userId: result!.user.id,
    passport: result!.passport,
    visa: result!.visa,
    visaProof: result!.visaProof,
    ETAWithSign: result!.ETAWithSign,
    facebook: result!.facebook,
    instagram: result!.instagram,
    twitter: result!.twitter,
    linkedIn: result!.linkedIn,
  });
}

export const convertGetAngelInvestorMemberRequestPayload = (
  payload?: GetResourceSuccessResponseType<AngelInvestorMember>,
) : AngelInvestorMemberApiType => {
  if (!identity.isObjWithChildren(payload?.result)) {
    return defaultAngelInvestorMember;
  }

  const { result } = payload!;

  return ({
    firstName: result!.firstName,
    lastName: result!.lastName,
    email: result!.email,
    dateOfBirth: identity.isFalsy(result!.dateOfBirth) ? '' : moment(result!.dateOfBirth).format(formatDateDashYMDTimeHMS),
    phoneNumber: result!.mobileNo,
    id: result!.id,
    location: {
      city: 'Dubai',
      country: 'UAE',
    },
    role: result!.jobTitle,
    gender: parseGender(result!.gender),
    nationality: result!.nationality.name,
    about: result!.bio,
    imageLink: result!.headshot,
    company: result!.angelInvestor?.name!,
    skills: [],
    jobFunctions: [],
    angelInvestorId: result!.angelInvestorID,
    numberOfKids: result!.numberOfKids,
    nationalityId: result!.nationality.id,
    emiratesId: result!.emirates,
    userId: result!.user.id,
    passport: result!.passport,
    visa: result!.visa,
    visaProof: result!.visaProof,
    ETAWithSign: result!.ETAWithSign,
    facebook: result!.facebook,
    instagram: result!.instagram,
    twitter: result!.twitter,
    linkedIn: result!.linkedIn,
  });
}

export const convertGetTechnicalLicenseMemberRequestPayload = (
  payload?: GetResourceSuccessResponseType<TechnicalLicenseMember>,
) : TechnicalLicenseMemberApiType => {
  if (!identity.isObjWithChildren(payload?.result)) {
    return defaultTechnicalLicenseMember;
  }

  const { result } = payload!;

  return ({
    firstName: result!.firstName,
    lastName: result!.lastName,
    email: result!.email,
    dateOfBirth: identity.isFalsy(result!.dateOfBirth) ? '' : moment(result!.dateOfBirth).format(formatDateDashYMDTimeHMS),
    phoneNumber: result!.mobileNo,
    id: result!.id,
    location: {
      city: 'Dubai',
      country: 'UAE',
    },
    role: result!.jobTitle,
    gender: parseGender(result!.gender),
    nationality: result!.nationality.name,
    about: result!.bio,
    imageLink: result!.headshot,
    company: result!.technicalLicense?.name!,
    skills: [],
    jobFunctions: [],
    technicalLicenseId: result!.technicalLicenseID,
    numberOfKids: result!.numberOfKids,
    nationalityId: result!.nationality.id,
    emiratesId: result!.emirates,
    userId: result!.user.id,
    passport: result!.passport,
    visa: result!.visa,
    visaProof: result!.visaProof,
    ETAWithSign: result!.ETAWithSign,
    facebook: result!.facebook,
    instagram: result!.instagram,
    twitter: result!.twitter,
    linkedIn: result!.linkedIn,
  });
}

export const convertTeamMemberRequestBody = (body: EditMemberApiType): UpdateTeamMemberRequestPayload => ({
  _date_of_birth: body.dateOfBirth,
  _first_name: body.firstName,
  _last_name: body.lastName,
  _job_title: body.role,
  _bio: body.about,
  _mobile_no: body.phoneNumber,
  _email: body.email,
  _gender: body.gender,
  _status_id: body.statusId,
  _number_of_kids: body.numberOfKids,
  _emirates: body.emiratesId,
  _headshot: body.imageLink,
  _hobbies: body.hobbies,
  _responsibilities: body.jobFunctions,
  _skills: body.skills,
  _nationality_id: body.nationalityId,
  _facebook: formatUrl(body.facebook),
  _instagram: formatUrl(body.instagram),
  _twitter: formatUrl(body.twitter),
  _linked_in: formatUrl(body.linkedIn),
  _visa: body?.visa,
  _passport: body?.passport,
});

export const convertAngelInvestorMemberRequestBody = (
  body: EditAngelInvestorMemberApiType,
): UpdateTeamMemberRequestPayload => ({
  _date_of_birth: body.dateOfBirth,
  _first_name: body.firstName,
  _last_name: body.lastName,
  _job_title: body.role,
  _bio: body.about,
  _mobile_no: body.phoneNumber,
  _email: body.email,
  _gender: body.gender,
  _status_id: body.statusId,
  _number_of_kids: body.numberOfKids,
  _emirates: body.emiratesId,
  _headshot: body.imageLink,
  _nationality_id: body.nationalityId,
  _facebook: formatUrl(body.facebook),
  _instagram: formatUrl(body.instagram),
  _twitter: formatUrl(body.twitter),
  _linked_in: formatUrl(body.linkedIn),
  _visa: body?.visa,
  _passport: body?.passport,
});

export const convertTechnicalLicenseMemberRequestBody = (
  body: EditTechnicalLicenseMemberApiType,
): UpdateTeamMemberRequestPayload => ({
  _date_of_birth: body.dateOfBirth,
  _first_name: body.firstName,
  _last_name: body.lastName,
  _job_title: body.role,
  _bio: body.about,
  _mobile_no: body.phoneNumber,
  _email: body.email,
  _gender: body.gender,
  _status_id: body.statusId,
  _number_of_kids: body.numberOfKids,
  _emirates: body.emiratesId,
  _headshot: body.imageLink,
  _nationality_id: body.nationalityId,
  _facebook: formatUrl(body.facebook),
  _instagram: formatUrl(body.instagram),
  _twitter: formatUrl(body.twitter),
  _linked_in: formatUrl(body.linkedIn),
  _visa: body?.visa,
  _passport: body?.passport,
});

export const convertTeamMembers = (payload?: GetResourceSuccessResponseType<TeamMember[]>): MemberApiType[] => {
  if (identity.isFullArray(payload?.result)) {
    return payload!.result!.map((member) => convertGetTeamMemberRequestPayload({ result: member }))
  }
  return [];
}

export const convertAngelInvestorMembers = (
  payload?: GetResourceSuccessResponseType<AngelInvestorMember[]>,
): AngelInvestorMemberApiType[] => {
  if (identity.isFullArray(payload?.result)) {
    return payload!.result!.map((member) => convertGetAngelInvestorMemberRequestPayload({ result: member }))
  }
  return [];
}

export const convertTechnicalLicenseMembers = (
  payload?: GetResourceSuccessResponseType<TechnicalLicenseMember[]>,
): TechnicalLicenseMemberApiType[] => {
  if (identity.isFullArray(payload?.result)) {
    return payload!.result!.map((member) => convertGetTechnicalLicenseMemberRequestPayload({ result: member }))
  }
  return [];
}

export const applyFilters = (params: GetTeamMembersParams, preload: boolean = false) => {
  const {
    filters, limit, offset, startupId, founder, groupBy, fieldSelection, technicalLicenseId, angelInvestorId,
  } = params;
  const {
    startups, nationalities, roles, gender, skills, searchString, technicalLicenses,
  } = filters || {};
  const config: HttpRequestConfig = {
    params: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramsSerializer: (p: any) => qs.stringify(p, { encode: false }),
  };
  const queryObject: {[key: string]: string | number | undefined} = {
    startup_id: identity.isPositiveNumber(startupId) ? startupId : undefined,
    gender: identity.isPositiveNumber(gender) ? gender : undefined,
    founder: identity.isFalsy(founder) ? founder : Number(founder),
    technical_license_id: identity.isPositiveNumber(technicalLicenseId) ? technicalLicenseId : undefined,
    angel_investor_id: identity.isPositiveNumber(angelInvestorId) ? angelInvestorId : undefined,
  };
  const configParams = queryBuilder(queryObject)
    .in({
      startup_id: identity.isFullArray(startups) ? startups : undefined,
      nationality_id: identity.isFullArray(nationalities) ? nationalities : undefined,
      job_title: identity.isFullArray(roles) ? roles : undefined,
      technical_license_id: identity.isFullArray(technicalLicenses) ? technicalLicenses : undefined,
    })
    .limit(limit)
    .offset(offset)
    .groupBy(groupBy)
    .select(fieldSelection)
  if (preload) {
    configParams.preload();
  }
  if (identity.isTruthyString(searchString)) {
    configParams.searchQuery(searchString!);
  }
  if (identity.isFullArray(skills)) {
    configParams.or(skills!.map((skill) => contains('skills', skill)));
  }
  config.params = configParams.toObject();
  return config;
}

export const convertGetInvolvementTypesResponse = (involvementType: InvolvementResponseType): InvolvementType => ({
  id: involvementType.id,
  name: involvementType.name,
});

export const convertGetInvolvementTypesListResponse = (response?: GetInvolvementTypesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((involvementType) => convertGetInvolvementTypesResponse(involvementType));
  }
}

export const convertSelectedData = (selectedField: string, response?: GetResourceSuccessResponseType<TeamMember[]>) => {
  if (identity.isFullArray(response?.result)) {
    switch (selectedField) {
      case 'skills': {
        let skillsString = '';
        response?.result!.forEach((item) => {
          const skill = identity.isTruthyString(item.skills) ? item.skills.trim() : '';
          if (identity.isTruthyString(skill)) {
            skillsString += `${identity.isTruthyString(skillsString) ? ',' : ''}${item.skills}`;
          }
        });
        const uniqueSkills: string[] = [];
        stringToArray(skillsString).forEach((item) => {
          if (!uniqueSkills.includes(item)) {
            uniqueSkills.push(item);
          }
        });
        return uniqueSkills.map((skill: string) => ({ value: skill, label: skill, key: skill }));
      }
      case 'job_title': {
        const roles: LabeledValue[] = [];
        response?.result!.forEach(
          ({ job_title }) => {
            const hasRole = roles.find(({ value }) => value === job_title);
            if (identity.isTruthyString(job_title) && !hasRole) {
              roles.push({ value: job_title!, label: job_title!, key: job_title });
            }
          },
        );
        return roles;
      }
      default: {
        return [];
      }
    }
  }
}

export const convertPartnerMemberRequestPayload = (
  partnerId: number,
  payload: PartnerMemberType,
): AddPartnerMemberPayload => ({
  _active: '1',
  _email: payload.email,
  _first_name: payload.firstName || payload.name,
  _job_title: typeof payload.role === 'string' ? payload.role : payload.role?.join(', '),
  _last_name: payload.lastName,
  _mobile: payload.phoneNumber,
  _phone: payload.phoneNumber,
  _partner_id: partnerId,
  _photo: payload.photo,
});
