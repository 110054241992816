import React, { useMemo } from 'react';
import { Button } from 'antd';

import { RenderIf } from 'components/components';
import { UserRoleType } from 'types/auth';
import { isEitherAdmin } from 'utils';

type UploadButtonProps = {
  name: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  condition: boolean,
  status: number,
  userType: UserRoleType,
  disabled?: boolean,
  title?: string,
}

const ViewUploadButton = (props: UploadButtonProps) => {
  const {
    name, onClick, condition, status, userType, disabled, title,
  } = props;

  const buttonText = useMemo(() => {
    if (condition) {
      return `View ${name} details`;
    }
    return `${status === 2 ? 'Change' : 'Upload'} ${name}`;
  }, [condition, status]);

  return (
    <div className="edit-startup__view-upload-button__container">
      {isEitherAdmin(userType) && !condition
        ? (
          <Button
            disabled={disabled}
            className="secondary-button md"
            onClick={onClick}
            title={title}
          >
            {buttonText}
          </Button>
        )
        : (
          <Button
            disabled={disabled}
            className="secondary-button md"
            onClick={onClick}
            title={title}
          >
            {buttonText}
          </Button>
        )}
      <RenderIf condition={status === 2 && !isEitherAdmin(userType)}>
        <p className="edit-startup__view-upload-button__rejected-document-text">{`Your ${name} has been rejected. Please upload a new one.`}</p>
      </RenderIf>
    </div>
  );
}

ViewUploadButton.defaultProps = {
  disabled: false,
  title: '',
};

export default ViewUploadButton;
