import {
  GetServiceRequestsDocumentsParams,
  GetServiceRequestsParams,
  ServiceRequestDocumentType,
  ServiceRequestItemType,
  ServiceRequestType,
  UpdateServiceRequestItemType,
} from 'packages/service_request_repository';
import {
  DataPayload,
  DataWithIdPayload,
  IdPayload,
  IsLoadingPayload,
} from 'types/reduxTypes/ActionTypes';
import {
  SetRequestPayload,
  GetRequestByOwnerId,
  ChangeRequestStatusPayload,
  AddNewRequestCommentPayload,
  SetCurrentTabPayload,
  SendNewRequestCommentPayload,
  SetCommentsPayload,
  SetActiveTabPayload,
  ChangePartnerRequestStatusPayload,
  RequestCommentsPayload,
  UpdateServiceRequestPayload,
  UpdatePartnerRequestPayload,
  SetCurrentUserTypePayload,
  GetServiceRequestByOwnerId,
} from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { RequestFiltersState, RequestsCountObject, RequestType } from 'types/requests';
import { ServicesType } from 'types/services';

export const types = {
  SET_REQUEST_OVERVIEW_FILTERS: 'SET_REQUEST_OVERVIEW_FILTERS',
  SET_HIGHLIGHT_REQUEST: 'SET_HIGHLIGHT_REQUEST',
  GET_REQUESTS_COUNT: 'GET_REQUESTS_COUNT',
  SET_REQUESTS_COUNT: 'SET_REQUESTS_COUNT',
  GET_REQUESTS: 'GET_REQUESTS',
  SET_REQUESTS: 'SET_REQUESTS',
  SET_REQUEST_ID: 'SET_REQUEST_ID',
  SET_PARTNER_REQUEST_ID: 'SET_PARTNER_REQUEST_ID',
  GET_REQUEST_BY_ID: 'GET_REQUEST_BY_ID',
  SET_REQUEST_BY_ID: 'SET_REQUEST_BY_ID',
  GET_REQUESTS_BY_OWNER_ID: 'GET_REQUESTS_BY_OWNER_ID',
  SET_SERVICE_REQUESTS: 'SET_SERVICE_REQUESTS',
  GET_SERVICE_REQUESTS_BY_OWNER_ID: 'GET_SERVICE_REQUESTS_BY_OWNER_ID',
  CHANGE_REQUEST_STATUS: 'CHANGE_REQUEST_STATUS',
  SET_REQUEST_STATUS: 'SET_REQUEST_STATUS',
  SET_REQUESTS_IS_LOADING: 'SET_REQUESTS_IS_LOADING',
  SET_COMMENTS_LOADING: 'SET_COMMENTS_LOADING',
  ADD_NEW_REQUEST_COMMENT: 'ADD_NEW_REQUEST_COMMENT',
  SEND_NEW_REQUEST_COMMENT: 'SEND_NEW_REQUEST_COMMENT',
  ADD_NEW_REQUEST: 'ADD_NEW_REQUEST',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  GET_COMMENTS_BY_REQUEST_ID: 'GET_COMMENTS_BY_REQUEST_ID',
  SET_COMMENTS: 'SET_COMMENTS',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  REMOVE_HIGHLIGHT: 'REMOVE_HIGHLIGHT',
  SEND_REQUEST: 'SEND_REQUEST',
  SET_UNSENT_REQUESTS: 'SET_UNSENT_REQUESTS',
  DELETE_DRAFT_REQUEST_ITEM: 'DELETE_DRAFT_REQUEST_ITEM',
  DELETE_DRAFT_REQUEST: 'DELETE_DRAFT_REQUEST',
  GET_UNSENT_REQUESTS: 'GET_UNSENT_REQUESTS',
  GET_REQUEST_ITEMS_BY_REQUEST_ID: 'GET_REQUEST_ITEMS_BY_REQUEST_ID',
  SET_REQUEST_ITEMS: 'SET_REQUEST_ITEMS',
  UPDATE_REQUEST_ITEM_BY_ID: 'UPDATE_REQUEST_ITEM_BY_ID',
  CHANGE_PARTNER_REQUEST_STATUS: 'CHANGE_PARTNER_REQUEST_STATUS',
  ACKNOWLEDGE_PARTNER_REQUEST_BY_ID: 'ACKNOWLEDGE_PARTNER_REQUEST_BY_ID',
  GET_SERVICES_BY_SP_ID: 'GET_SERVICES_BY_SP_ID',
  SET_SERVICES_BY_SP_ID: 'SET_SERVICES_BY_SP_ID',
  GET_SERVICE_REQUEST_DOCUMENTS: 'GET_SERVICE_REQUEST_DOCUMENTS',
  SET_SERVICE_REQUEST_DOCUMENTS: 'SET_SERVICE_REQUEST_DOCUMENTS',
  SET_SHOW_INVOICE_FIELDS: 'SET_SHOW_INVOICE_FIELDS',
  UPDATE_SERVICE_REQUEST: 'UPDATE_SERVICE_REQUEST',
  RESET_REQUESTS: 'RESET_REQUESTS',
  SET_CURRENT_USER_TYPE_TAB: 'SET_CURRENT_USER_TYPE_TAB',
}

export const resetRequests = (payload: object = {}) => ({
  type: types.RESET_REQUESTS,
  payload,
});

export const acknowledgePartnerRequestById = (payload: UpdatePartnerRequestPayload) => ({
  type: types.ACKNOWLEDGE_PARTNER_REQUEST_BY_ID,
  payload,
});

export const getRequestById = (payload: IdPayload) => ({
  type: types.GET_REQUEST_BY_ID,
  payload,
});

export const setRequestById = (payload: SetRequestPayload) => ({
  type: types.SET_REQUEST_BY_ID,
  payload,
});

export const getRequestsByOwnerId = (payload: GetRequestByOwnerId) => ({
  type: types.GET_REQUESTS_BY_OWNER_ID,
  payload,
});

export const getServiceRequestByOwnerId = (payload: GetServiceRequestByOwnerId) => ({
  type: types.GET_SERVICE_REQUESTS_BY_OWNER_ID,
  payload,
});

export const changeRequestStatus = (payload: ChangeRequestStatusPayload) => ({
  type: types.CHANGE_REQUEST_STATUS,
  payload,
});

export const getRequests = (payload: DataPayload<GetServiceRequestsParams> = { data: {} }) => ({
  type: types.GET_REQUESTS,
  payload,
});

export const getRequestsCount = (payload: object = {}) => ({
  type: types.GET_REQUESTS_COUNT,
  payload,
});

export const setRequests = (payload: DataPayload<ServiceRequestType[]>) => ({
  type: types.SET_REQUESTS,
  payload,
});

export const setServiceRequests = (payload: DataPayload<ServiceRequestType[]>) => ({
  type: types.SET_SERVICE_REQUESTS,
  payload,
});

export const setRequestsCount = (payload: DataPayload<RequestsCountObject>) => ({
  type: types.SET_REQUESTS_COUNT,
  payload,
});

export const setRequestStatus = (payload: ChangeRequestStatusPayload) => ({
  type: types.SET_REQUEST_STATUS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_REQUESTS_IS_LOADING,
  payload,
});

export const addNewRequestComment = (payload: AddNewRequestCommentPayload) => ({
  type: types.ADD_NEW_REQUEST_COMMENT,
  payload,
});

export const sendNewRequestComment = (payload: SendNewRequestCommentPayload) => ({
  type: types.SEND_NEW_REQUEST_COMMENT,
  payload,
});

export const setPartnerRequestId = (payload: IdPayload) => ({
  type: types.SET_PARTNER_REQUEST_ID,
  payload,
});

export const setRequestId = (payload: IdPayload) => ({
  type: types.SET_REQUEST_ID,
  payload,
});

export const addNewRequest = (payload: DataPayload<RequestType>) => ({
  type: types.ADD_NEW_REQUEST,
  payload,
});

export const removeHighlight = (payload: object = {}) => ({
  type: types.REMOVE_HIGHLIGHT,
  payload,
});

export const deleteDraftRequest = (payload: object = {}) => ({
  type: types.DELETE_DRAFT_REQUEST,
  payload,
});

export const deleteDraftRequestItem = (payload: Partial<IdPayload>) => ({
  type: types.DELETE_DRAFT_REQUEST_ITEM,
  payload,
});

export const setCurrentTab = (payload: SetCurrentTabPayload) => ({
  type: types.SET_CURRENT_TAB,
  payload,
});

export const getCommentsByRequestId = (payload: RequestCommentsPayload) => ({
  type: types.GET_COMMENTS_BY_REQUEST_ID,
  payload,
});

export const setComments = (payload: SetCommentsPayload) => ({
  type: types.SET_COMMENTS,
  payload,
});

export const setActiveTab = (payload: SetActiveTabPayload) => ({
  type: types.SET_ACTIVE_TAB,
  payload,
});

export const sendRequest = (payload: DataWithIdPayload<RequestType[]>) => ({
  type: types.SEND_REQUEST,
  payload,
});

export const setUnsentRequests = (payload: DataPayload<ServiceRequestItemType[]>) => ({
  type: types.SET_UNSENT_REQUESTS,
  payload,
});

export const setHighlightRequest = (payload: object = {}) => ({
  type: types.SET_HIGHLIGHT_REQUEST,
  payload,
});

export const getUnsentRequests = (payload: object = {}) => ({
  type: types.GET_UNSENT_REQUESTS,
  payload,
});

export const getRequestItemsByRequestId = (payload: IdPayload) => ({
  type: types.GET_REQUEST_ITEMS_BY_REQUEST_ID,
  payload,
});

export const setRequestItems = (payload: DataPayload<ServiceRequestItemType[]>) => ({
  type: types.SET_REQUEST_ITEMS,
  payload,
});

export const updateRequestItemById = (
  payload: DataPayload<IdPayload & UpdateServiceRequestItemType & { servicePrice: number }>,
) => ({
  type: types.UPDATE_REQUEST_ITEM_BY_ID,
  payload,
});

export const setCommentsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_COMMENTS_LOADING,
  payload,
});

export const changePartnerRequestStatus = (payload: ChangePartnerRequestStatusPayload) => ({
  type: types.CHANGE_PARTNER_REQUEST_STATUS,
  payload,
});

export const getServicesBySPId = (payload: IdPayload) => ({
  type: types.GET_SERVICES_BY_SP_ID,
  payload,
});

export const setServicesBySPId = (payload: DataPayload<ServicesType[]>) => ({
  type: types.SET_SERVICES_BY_SP_ID,
  payload,
});

export const getServiceRequestDocuments = (payload: GetServiceRequestsDocumentsParams) => ({
  type: types.GET_SERVICE_REQUEST_DOCUMENTS,
  payload,
});

export const setServiceRequestDocuments = (payload: DataPayload<ServiceRequestDocumentType[]>) => ({
  type: types.SET_SERVICE_REQUEST_DOCUMENTS,
  payload,
});

export const setShowInvoiceFields = (payload: DataPayload<boolean>) => ({
  type: types.SET_SHOW_INVOICE_FIELDS,
  payload,
});

export const updateServiceRequest = (payload: UpdateServiceRequestPayload) => ({
  type: types.UPDATE_SERVICE_REQUEST,
  payload,
});

export const setRequestOverviewFilters = (payload: DataPayload<RequestFiltersState>) => ({
  type: types.SET_REQUEST_OVERVIEW_FILTERS,
  payload,
});

//  Request Tab Filters
export const setCurrentUserTypeTab = (payload: SetCurrentUserTypePayload) => ({
  type: types.SET_CURRENT_USER_TYPE_TAB,
  payload,
});
