import { combineReducers } from 'redux';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';

import httpClient from 'packages/http_client';

import workspaceReducer from './workspace';
import authReducer from './auth';
import cohortReducer from './cohort';
import startupsReducer from './startups';
import settingsOverviewReducer from './settingsOverview';
import activitiesReducer from './activities';
import membersReducer from './memberDetails';
import angelInvestorMembersReducer from './angelInvestorMemberDetails';
import peopleReducer from './people';
import requestReducer from './request';
import serviceProvidersReducer from './serviceProviders';
import walletReducer from './wallet';
import aiWalletReducer from './ai_wallet';
import servicesReducer from './services';
import eventsReducer from './events';
import partnersReducer from './partners';
import dashboardReducer from './dashboard';
import notificationsReducer from './notifications';
import performanceReducer from './performance';
import userReducer from './users';
import angelInvestorsReducer from './angelInvestors';
import articlesReducer from './articles'
import technicalLicenseReducer from './technicalLicense'
import technicalLicenseMembersReducer from './technicalLicenseMemberDetails'

const driver = createDriver(httpClient);

const { requestsReducer } = handleRequests({ driver, cache: true });

export default combineReducers({
  requests: requestsReducer,
  workspaceReducer,
  authReducer,
  cohortReducer,
  startupsReducer,
  settingsOverviewReducer,
  activitiesReducer,
  membersReducer,
  angelInvestorMembersReducer,
  peopleReducer,
  requestReducer,
  serviceProvidersReducer,
  walletReducer,
  aiWalletReducer,
  servicesReducer,
  eventsReducer,
  partnersReducer,
  dashboardReducer,
  notificationsReducer,
  performanceReducer,
  userReducer,
  angelInvestorsReducer,
  articlesReducer,
  technicalLicenseReducer,
  technicalLicenseMembersReducer,
});
