import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useLocation } from 'react-router';
import moment from 'moment';

import { Input, RenderIf } from 'components/components';
import {
  DRAWERS,
  identity,
  isEitherAdmin,
  requiredValidation,
} from 'utils';
import { IAUploadFormProps } from 'types/angelInvestors';
import { UpdateCreateIncentiveAgreementType } from 'packages/angel_investors_repository';
import { formatDashYMD, formatSlashMDY } from '../../../utils/constants/formatters';
import { getAngelInvestorFile } from '../../utils';

import '../IncentiveAgreementDetailsDrawer';

const { useForm } = Form;

const IAUploadForm = (props: IAUploadFormProps) => {
  const { incentiveAgreement, angelInvestorID, userType } = props;
  const [file, setFile] = useState<Blob | string>('');
  const [form] = useForm();
  const location = useLocation();

  useEffect(() => {
    if (isEitherAdmin(userType)) {
      setFile(incentiveAgreement.file);
    }
  }, [incentiveAgreement.file]);

  const isApprovedIncentiveAgreement = useMemo(() => location.pathname.includes('/cohorts/details') && isEitherAdmin(userType), [location]);

  const onSubmit = (formData: UpdateCreateIncentiveAgreementType) => {
    const data: UpdateCreateIncentiveAgreementType = { angelInvestorID };
    if (identity.isZero(incentiveAgreement.id)) {
      data.file = file;
      props.actions.createIncentiveAgreement({ data });
    } else {
      if (isEitherAdmin(userType)) {
        data.startDate = formData.startDate ? moment(formData.startDate).utc().format(formatDashYMD) : '';
        data.endDate = formData.endDate ? moment(formData.endDate).utc().format(formatDashYMD) : '';
        data.walletAmount = formData.walletAmount || 0;
      } else {
        data.file = file;
      }
      props.actions.updateIncentiveAgreement({ data, id: incentiveAgreement.id });
    }

    if (isApprovedIncentiveAgreement) {
      props.actions.setDrawerBody({ drawerBody: DRAWERS.incentiveAgreement });
    }
  }

  const beforeUpload = (uploadFile: RcFile) => {
    setFile(uploadFile as Blob);
    form.setFieldValue('file', uploadFile as Blob);
    return false;
  }

  // let status = ''
  // switch (statusId) {
  //   case 0: {
  //     status = 'Waiting for Acceptance Letter.';
  //     break;
  //   }
  //   case 1: {
  //     status = 'Acceptance Letter Sent. Awaiting Hub71 CEO\'s signature.';
  //     break;
  //   }
  //   case 2: {
  //     status = `Acceptance Letter Sent. Awaiting ${info.name}'s signature.`;
  //     break;
  //   }
  //   default: {
  //     status = 'Approved.';
  //     break;
  //   }
  // }

  return (
    <div className="incentive-agreement-upload-drawer">
      <Form form={form} onFinish={onSubmit}>
        <RenderIf condition={isApprovedIncentiveAgreement}>
          <Input label="Start Date" name="startDate" required requiredMark rules={[requiredValidation]}>
            <Input.DatePicker placeholder="Start Date" format={formatSlashMDY} />
          </Input>
          <Input label="End Date" name="endDate" required requiredMark rules={[requiredValidation]}>
            <Input.DatePicker placeholder="End Date" format={formatSlashMDY} />
          </Input>
          <Input label="Wallet Amount" name="walletAmount" required requiredMark rules={[requiredValidation]}>
            <Input.InputNumber placeholder="wallet amount in AED" />
          </Input>
        </RenderIf>

        <RenderIf condition={!isApprovedIncentiveAgreement}>
          <Input name="file" required requiredMark rules={[requiredValidation]}>
            <Input.DraggerUpload
              accept=".pdf"
              beforeUpload={beforeUpload}
              previewType="image"
              isRoundableButton
              defaultFileList={isEitherAdmin(userType) ? [...getAngelInvestorFile(incentiveAgreement?.file)] : []}
            />
          </Input>
        </RenderIf>
        <div className="submit-button">
          <Button type="primary" htmlType="submit" className="primary-button">Submit</Button>
        </div>
      </Form>
    </div>
  );
}

export default IAUploadForm;
