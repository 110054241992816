import httpClient, {
  ApiResponseType,
  DeleteResourceSuccessResponseType,
  FORM_DATA_CONFIG,
  GetResourceSuccessResponseType,
  HttpRequestConfig,
  queryBuilder,
} from 'packages/http_client';
import identity from 'utils/identity';
import {
  AddServicePriceApiResponseType,
  CreateServiceApiResponseType,
  CreateServiceCategoryApiRequestPayload,
  CreateServiceCategoryApiResponseType,
  CreateServiceCategoryType,
  CreateServicePayload,
  CreateServiceResponse,
  CreateServiceSubCategoryApiRequestPayload,
  GetServiceApiResponseType,
  GetServiceCategoriesApiResponseType,
  GetServicePricesByServiceType,
  GetServicesCountResponse,
  GetServicesListApiResponseType,
  GetServicesListCountApiResponseType,
  GetServiceSubCategoriesApiResponseType,
  ResponseUnitType,
  ServiceCategoryParams,
  ServiceCategoryType,
  ServicePriceApiRequestPayload,
  ServicePriceType,
  ServiceQuery,
  ServiceSubCategoryParams,
  ServiceSubCategoryType,
  ServiceType,
  UnitType,
  UpdateServiceApiResponseType,
  UpdateServicePayload,
} from '../types';
import {
  convertCreateServiceCategoryPayload,
  convertCreateServicePayload,
  convertCreateServiceSubCategoryPayload,
  convertGetServiceResponse,
  convertGetServicesListResponse,
  convertServiceCategoryBody,
  convertServicePriceRequestPayload,
  convertServiceSubCategoryBody,
  convertServiceUnits,
  convertUpdateServicePayload,
  formatQuery,
  SERVICE_API,
  SERVICE_CATEGORY_API,
  SERVICE_PRICE_API,
  SERVICE_SUB_CATEGORY_API,
  UNIT_API,
  UNIT_TO_NUMBER_MAP,
} from '../utils';

/** SERVICE APIs */
const createNewServiceApi = (payload: CreateServicePayload) => {
  const url = SERVICE_API;
  const requestPayload = convertCreateServicePayload(payload);
  return httpClient.post<ApiResponseType<CreateServiceApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const getServiceApi = (id: number, isPreload: boolean) => {
  const url = `${SERVICE_API}/${id}`;
  const config: HttpRequestConfig = {
    params: { $preload: isPreload ? 1 : 0 },
  };
  return httpClient.get<ApiResponseType<GetServiceApiResponseType>>(url, config);
};

const getServicesListApi = async (query: ServiceQuery, serviceProviderId?: number) => {
  const url = SERVICE_API;
  const config: HttpRequestConfig = { params: {} };

  if (identity.isPositiveNumber(serviceProviderId)) {
    config.params.$preload = 1;
    config.params.$limit = query.limit;
    config.params.$offset = query.offset;
    config.params.service_provider_id = serviceProviderId;
  } else {
    config.params = formatQuery(query);
  }

  return httpClient.get<ApiResponseType<GetServicesListApiResponseType>>(url, config);
};

const getServicesListCountApi = (query: ServiceQuery, serviceProviderId?: number) => {
  const url = `${SERVICE_API}`;
  const config: HttpRequestConfig = { params: {} };
  if (identity.isPositiveNumber(serviceProviderId)) {
    config.params.$preload = 1;
    config.params.$f = 'id__count';
    config.params.$limit = query.limit;
    config.params.$offset = query.offset;
    config.params.service_provider_id = serviceProviderId;
    config.params.is_available_for_ai_community = query.filters?.isAvailableForAiCommunity;
  } else {
    config.params = formatQuery({
      ...query,
      filters: {
        ...query.filters,
        fieldSelection: [
          {
            field: 'id', aggregation: 'count',
          },
        ],
      },
    });
  }

  return httpClient.get<ApiResponseType<GetServicesListCountApiResponseType>>(url, config);
}

const updateServiceApi = async (serviceId: number, payload: UpdateServicePayload) => {
  const url = `${SERVICE_API}/${serviceId}`;
  const requestPayload = convertUpdateServicePayload(payload);
  return httpClient.put<ApiResponseType<UpdateServiceApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

/** SERVICE PRICE APIs */
const addPriceForServiceApi = (payload: ServicePriceApiRequestPayload) => {
  const url = `${SERVICE_PRICE_API}`;
  return httpClient.post<ApiResponseType<AddServicePriceApiResponseType>>(url, payload, FORM_DATA_CONFIG);
};

const getServicePricesForServiceApi = (serviceId: number) => {
  const url = SERVICE_PRICE_API;
  const config: HttpRequestConfig = {
    params: {
      $preload: 1,
      service_id: serviceId,
    },
  };

  return httpClient.get<ApiResponseType<GetServicePricesByServiceType>>(url, config);
};

const updateServicePriceApi = (servicePriceId: number, payload: ServicePriceApiRequestPayload) => {
  const url = `${SERVICE_PRICE_API}/${servicePriceId}`;
  return httpClient.put<ApiResponseType<UpdateServiceApiResponseType>>(url, payload, FORM_DATA_CONFIG);
};

/** SERVICE CATEGORY APIs */
const getServiceCategoriesApi = () => {
  const url = `${SERVICE_CATEGORY_API}`;
  return httpClient.get<ApiResponseType<GetServiceCategoriesApiResponseType>>(url);
};

const getServiceCategoriesByIdsApi = (type: string) => {
  const url = `${SERVICE_CATEGORY_API}`;
  const config: HttpRequestConfig = {
    params: {
      ...(type === 'angel-investor' && { is_available_for_angel_investor: 1 }),
    },
  };

  return httpClient.get<ApiResponseType<GetServiceCategoriesApiResponseType>>(url, config);
};

const getServiceSubCategoriesApi = (categoryId?: number) => {
  const url = SERVICE_SUB_CATEGORY_API;
  const config: HttpRequestConfig = {};
  if (identity.isTruthyNumber(categoryId)) {
    config.params = {};
    config.params.service_category_id = categoryId;
  }

  return httpClient.get<ApiResponseType<GetServiceSubCategoriesApiResponseType>>(url, config);
};

const getAllServiceSubCategoriesApi = () => {
  const url = SERVICE_SUB_CATEGORY_API;
  const config: HttpRequestConfig = {};
  config.params = {
    $preload: 1,
  };

  return httpClient.get<ApiResponseType<GetServiceSubCategoriesApiResponseType>>(url, config);
};

/** SERVICE API WRAPPER METHODS */
export const createNewService = async (payload: CreateServicePayload): Promise<CreateServiceResponse> => {
  const response = await createNewServiceApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
};

export const getService = async (id: number, isPreload?: boolean):
Promise<ApiResponseType<ServiceType>> => {
  const response = await getServiceApi(id, isPreload!);
  const { data, error, httpStatus } = response;
  return { data: convertGetServiceResponse(data?.result), error, httpStatus };
};

export const getServicesList = async (query: ServiceQuery, serviceProviderId?: number):
Promise<ApiResponseType<ServiceType[]>> => {
  const response = await getServicesListApi(query, serviceProviderId);
  const { data, error, httpStatus } = response;
  return { data: convertGetServicesListResponse(data?.result), error, httpStatus };
};

export const updateService = async (serviceId: number, payload: UpdateServicePayload):
Promise<ApiResponseType<UpdateServiceApiResponseType>> => {
  const response = await updateServiceApi(serviceId, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

/** SERVICE CATEGORY API WRAPPER METHODS */
export const getAllServiceCategories = async (): Promise<ApiResponseType<ServiceCategoryType[]>> => {
  const response = await getServiceCategoriesApi();
  const { data, error, httpStatus } = response;
  return { data: data?.result, error, httpStatus };
};

export const getServiceCategoriesFilter = async (
  type: 'angel-investor',
): Promise<ApiResponseType<ServiceCategoryType[]>> => {
  const response = await getServiceCategoriesByIdsApi(type);
  const { data, error, httpStatus } = response;

  return { data: data?.result, error, httpStatus };
};

export const getServiceSubCategories = async (categoryId: number):
Promise<ApiResponseType<ServiceSubCategoryType[]>> => {
  const response = await getServiceSubCategoriesApi(categoryId);
  const { data, error, httpStatus } = response;
  return { data: data?.result, error, httpStatus };
};

export const getServiceSubCategoriesAll = async (): Promise<ApiResponseType<ServiceSubCategoryType[]>> => {
  const response = await getAllServiceSubCategoriesApi();
  const { data, error, httpStatus } = response;
  return { data: data?.result, error, httpStatus };
};

/** SERVICE PRICE API WRAPPER METHODS */
export const addPriceForService = async (payload: ServicePriceType) => {
  const requestPayload = convertServicePriceRequestPayload(payload);
  return await addPriceForServiceApi(requestPayload);
};

export const editServicePrice = async (servicePriceId: number, payload: ServicePriceType) => {
  const requestPayload = convertServicePriceRequestPayload(payload);
  return await updateServicePriceApi(servicePriceId, requestPayload);
};

export const getServicePriceForService = async (serviceId: number): Promise<ApiResponseType<ServicePriceType>> => {
  const response = await getServicePricesForServiceApi(serviceId);
  const { data, error, httpStatus } = response;
  return { data: data?.result![0], error, httpStatus };
};

/** SERVICES COUNTER */
export const getServicesCount = async (query: ServiceQuery, serviceProviderId?: number)
  : Promise<GetServicesCountResponse> => {
  const response = await getServicesListCountApi(query, serviceProviderId);
  const { error, data, httpStatus } = response;
  const servicesCount = data?.result[0].id__count;
  return { error, httpStatus, servicesCount };
}

/** SERVICE CATEGORIES AND SUBCATEGORIES */
const createNewServiceCategoryApi = (payload: ServiceCategoryType) => {
  const url = SERVICE_CATEGORY_API;
  const requestPayload = convertCreateServiceCategoryPayload(payload);
  return httpClient.post<ApiResponseType<CreateServiceCategoryApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

export const createNewServiceCategory = async (payload: ServiceCategoryType): Promise<CreateServiceResponse> => {
  const response = await createNewServiceCategoryApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
};

const createNewServiceSubCategoryApi = (payload: ServiceSubCategoryType) => {
  const url = SERVICE_SUB_CATEGORY_API;
  const requestPayload = convertCreateServiceSubCategoryPayload(payload);
  return httpClient.post<
    ApiResponseType<CreateServiceCategoryApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

export const createNewSubServiceCategory = async (payload: ServiceSubCategoryType): Promise<CreateServiceResponse> => {
  const response = await createNewServiceSubCategoryApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id[0] };
};

const deleteCategoriesApi = (params?: ServiceCategoryParams) => {
  const url = SERVICE_CATEGORY_API;
  const config = { params };
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url, config);
}

export const deleteCategories = async (params?: ServiceCategoryParams) => {
  const response = await deleteCategoriesApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

const deleteSubCategoriesApi = (params?: ServiceSubCategoryParams) => {
  const url = SERVICE_SUB_CATEGORY_API;
  const config = { params };
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url, config);
}

export const deleteSubCategories = async (params?: ServiceCategoryParams) => {
  const response = await deleteSubCategoriesApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

const updateServiceSubCategoryByIdApi = (id: number, payload?: CreateServiceSubCategoryApiRequestPayload) => {
  const url = `${SERVICE_SUB_CATEGORY_API}/${id}`;
  return httpClient.put<ApiResponseType<DeleteResourceSuccessResponseType>>(url, payload);
};

export const updateServiceSubCategoryById = async (id: number, payload: CreateServiceCategoryType) => {
  const response = await updateServiceSubCategoryByIdApi(id, convertServiceSubCategoryBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const deleteServiceSubCategoryByIdApi = (id: number) => {
  const url = `${SERVICE_SUB_CATEGORY_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

export const deleteSubCategoryById = async (id: number) => {
  const response = await deleteServiceSubCategoryByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const updateServiceCategoryByIdApi = (id: number, payload?: CreateServiceCategoryApiRequestPayload) => {
  const url = `${SERVICE_CATEGORY_API}/${id}`;
  return httpClient.put<ApiResponseType<DeleteResourceSuccessResponseType>>(url, payload);
};

export const updateServiceCategoryById = async (id: number, payload: CreateServiceCategoryType) => {
  const response = await updateServiceCategoryByIdApi(id, convertServiceCategoryBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const deleteServiceCategoryByIdApi = (id: number) => {
  const url = `${SERVICE_CATEGORY_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

export const deleteCategoryById = async (id: number) => {
  const response = await deleteServiceCategoryByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

/** SERVICES COUNTER */
const getServiceTimeUnitsApi = () => {
  const url = UNIT_API;
  const config: HttpRequestConfig = {
    params: queryBuilder().in({ type: [UNIT_TO_NUMBER_MAP.Time, UNIT_TO_NUMBER_MAP.Fixed] }).toObject(),
  }
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<ResponseUnitType[]>>>(url, config);
};

export const getServiceTimeUnits = async (): Promise<ApiResponseType<UnitType[]>> => {
  const response = await getServiceTimeUnitsApi();
  const { data, error, httpStatus } = response;
  return { data: convertServiceUnits(data?.result), error, httpStatus };
};
