import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { getDashboardCount } from 'redux/actions/dashboard';
import { selectDashboardCounts } from 'redux/selectors/dashboard';
import { setCurrentTab } from 'redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  count: selectDashboardCounts(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setCurrentTab,
    getDashboardCount,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
