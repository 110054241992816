import httpClient, { ApiResponseType } from 'packages/http_client';
import {
  ChangePasswordApiRequestPayload,
  ChangePasswordRequestPayload,
  ChangePasswordResponse,
  ChangePasswordResponseType,
  LoginApiResponseType,
  LoginRequestPayload,
  LoginResponse,
  LogoutResponse,
  LogoutResponseType,
  ResetPasswordApiResponseType,
  ResetPasswordOtpRequestPayload,
  ResetPasswordRequestPayload,
  ResetPasswordResponse,
} from './types';
import {
  AUTH_API_PREFIX,
  API_PREFIX,
  CHANGE_PASSWORD_API,
  convertChangePasswordRequestPayload,
  convertLoginApiResponse,
  LOGIN_API,
  LOGOUT_API,
  RESET_PASSWORD_API,
} from './utils';

const loginApi = (payload: LoginRequestPayload) => {
  const url = `${AUTH_API_PREFIX}/${LOGIN_API}`;
  return httpClient.post<ApiResponseType<LoginApiResponseType>>(url, new URLSearchParams(payload));
};

const logoutApi = () => {
  const url = `${AUTH_API_PREFIX}/${LOGOUT_API}`;
  return httpClient.post<ApiResponseType<LogoutResponseType>>(url);
};

const resetPasswordApi = (payload: ResetPasswordRequestPayload) => {
  const url = `${API_PREFIX}/${RESET_PASSWORD_API}`;
  return httpClient.post<ApiResponseType<ResetPasswordApiResponseType>>(url, new URLSearchParams(payload));
};

const changePasswordApi = (payload: ChangePasswordApiRequestPayload) => {
  const url = `${API_PREFIX}/${CHANGE_PASSWORD_API}`;
  return httpClient.post<ApiResponseType<ChangePasswordResponseType>>(url, payload);
};

export const login = async (payload: LoginRequestPayload): Promise<LoginResponse> => {
  const response = await loginApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertLoginApiResponse(data, payload) };
};

export const logout = async (): Promise<LogoutResponse> => await logoutApi();

export const resetPassword = async (
  payload: ResetPasswordRequestPayload | ResetPasswordOtpRequestPayload,
): Promise<ResetPasswordResponse> => {
  const response = await resetPasswordApi(payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const changePassword = async (payload: ChangePasswordRequestPayload): Promise<ChangePasswordResponse> => {
  const convertedPayload = convertChangePasswordRequestPayload(payload);
  return await changePasswordApi(convertedPayload);
};
