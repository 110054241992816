import React from 'react';
import classnames from 'classnames';
import {
  Form,
  Input as AntdInput,
  DatePicker,
  InputNumber,
  Switch,
} from 'antd';

import { InputProps } from 'types/componentTypes/Input';
import {
  Dragger,
  UploadImage,
  Dropdown,
  Heading,
  CheckBoxGroup,
  Radio,
  Select,
  RadioTags,
  AddButton,
  Slider,
  SelectTags,
  Password,
} from './components';

import './Input.scss';

const { Item } = Form;

const Input = (props: InputProps) => (
  <div className={classnames('input', props.className)}>
    <Item
      colon={false}
      label={props.label}
      name={props.name}
      labelCol={props.labelCol}
      tooltip={props.tooltip}
      required={props.required}
      rules={props.rules}
      initialValue={props.initialValue}
      requiredMark={props.requiredMark}
      className={classnames('form-item', { 'form-item-vertical': props.layout === 'vertical' })}
    >
      { props.children }
    </Item>
  </div>
)

Input.InputField = AntdInput;
Input.DatePicker = DatePicker;
Input.InputNumber = InputNumber;
Input.Radio = Radio;
Input.DraggerUpload = Dragger;
Input.UploadImage = UploadImage;
Input.Dropdown = Dropdown;
Input.Heading = Heading;
Input.CheckboxGroup = CheckBoxGroup;
Input.Select = Select;
Input.AddButton = AddButton;
Input.Slider = Slider;
Input.SelectTags = SelectTags;
Input.RadioTags = RadioTags;
Input.Switch = Switch;
Input.Password = Password;

Input.defaultProps = {
  layout: 'horizontal',
}

export default Input;
