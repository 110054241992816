import {
  serviceProvidersUrl,
  cohortsUrl,
  cohortUrls,
  resetPasswordUrl,
  loginUrl,
  addMissingDetailsUrl,
  settingsOverviewUrl,
  submitOverviewUrl,
  startupUrls,
  angelInvestorsUrl,
  technicalLicensesUrl,
  requestsUrl,
  membersUrl,
  startupsUrl,
  peopleUrl,
  peopleUrls,
  eventsUrl,
  partnersUrl,
  teamMembersUrl,
  walletUrl,
  angelInvestorWalletUrl,
  performanceUrl,
  serviceProvidersUrls,
  requestUrls,
  changePasswordUrl,
  servicesUrl,
  dashboardUrl,
  partnerUrls,
  servicesAndSpsOverviewUrl,
  performanceUrls,
  profileUrls,
  articlesUrl,
  angelInvestorUrls, forbidden,
} from './urls'

const {
  myProfileUrl,
  editMyProfileUrl,
  companyProfileUrl,
  editCompanyProfileUrl,
  startupProfileUrl,
  editStartupProfileUrl,
  technicalLicenseProfileUrl,
  editTechnicalLicenseProfileUrl,
} = profileUrls;

const { createPerformanceReportUrl } = performanceUrls;
const { startupRejectedUrl } = startupUrls;

const routes = {
  // define all url templates here
  serviceProvidersUrl,
  myProfileUrl,
  editMyProfileUrl,
  companyProfileUrl,
  editCompanyProfileUrl,
  startupProfileUrl,
  editStartupProfileUrl,
  editServiceProviderUrl: `${serviceProvidersUrls.editServiceProviderUrl}/:id`,
  serviceProviderMoreDetailsUrl: `${serviceProvidersUrls.serviceProviderMoreDetailsUrl}/:id`,
  membersUrl,
  cohortsUrl,
  loginUrl,
  resetPasswordUrl,
  settingsOverviewUrl,
  editCohortUrl: `${cohortUrls.editCohortUrl}/:id`,
  cohortsDetailUrl: `${cohortUrls.cohortsDetailUrl}/:id`,
  addMissingDetailsUrl,
  submitOverviewUrl,
  startupDetailsUrl: `${startupUrls.startupDetailsUrl}/:id`,
  requestsUrl,
  editPeopleProfileUrl: `${peopleUrls.editPeopleUrl}/:type/:id`,
  startupsUrl,
  angelInvestorsUrl,
  angelInvestorsDetailsUrl: `${angelInvestorUrls.angelInvestorDetailsUrl}/:id`,
  editAngelInvestorsUrl: `${angelInvestorUrls.editAngelInvestorUrl}/:id`,
  technicalLicensesUrl,
  editStartupUrl: `${startupUrls.editStartupUrl}/:id`,
  peopleUrl,
  peopleDetailsUrl: `${peopleUrls.peopleDetailsUrl}/:type/:id`,
  eventsUrl,
  partnersUrl,
  editPartnerUrl: `${partnerUrls.editPartnerUrl}/:id`,
  partnerDetailsUrl: `${partnerUrls.partnerDetailsUrl}/:id`,
  teamMembersUrl,
  walletUrl,
  angelInvestorWalletUrl,
  performanceUrl,
  createPerformanceReportUrl,
  editPerformanceReportUrl: `${performanceUrls.editPerformanceReportUrl}/:id`,
  serviceProviderDetailsUrl: `${serviceProvidersUrls.serviceProviderDetailsUrl}/:id`,
  startupRejectedUrl,
  requestMoreDetailsUrl: `${requestUrls.requestMoreDetailsUrl}/:id`,
  viewAllServicesUrl: `${serviceProvidersUrls.viewAllServicesUrl}/:id`,
  changePasswordUrl,
  servicesUrl,
  dashboardUrl,
  servicesAndSpsOverviewUrl,
  serviceOverview: `${servicesUrl}/:id`,
  startupWallet: `${walletUrl}/:id`,
  angelInvestorWallet: `${angelInvestorWalletUrl}/:id`,
  articlesUrl: `${articlesUrl}/:id`,
  technicalLicenseProfileUrl,
  editTechnicalLicenseProfileUrl,
  forbidden,
};

export default routes;
