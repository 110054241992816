import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { getIncentiveAgreementById, updateAngelInvestorById } from 'redux/actions/angelInvestors';
import {
  selectIncentiveAgreement,
  selectAngelInvestorId,
  selectIncentiveAgreementStatus,
  selectAngelInvestorDetails,
} from 'redux/selectors/angelInvestors';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  info: selectAngelInvestorDetails(state),
  userType: selectUserType(state),
  incentiveAgreement: selectIncentiveAgreement(state),
  angelInvestorID: selectAngelInvestorId(state),
  incentiveAgreementStatus: selectIncentiveAgreementStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateAngelInvestorById,
    getIncentiveAgreementById,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
