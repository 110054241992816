import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Button, Form } from 'antd'
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  isAngelInvestor,
} from 'utils';
import {
  TitleWithActions,
  Input,
  Breadcrumb,
  EditProfileImage,
} from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { EditAngelInvestorProps, AngelInvestorType } from 'types/angelInvestors';
import routes from 'routes';
import { composeAngelInvestorDetailsUrl } from 'routes/routeComposers';
import { useEditAngelInvestorHook, editAIFormElements } from './utils';

import './EditAngelInvestor.scss';

const EditAngelInvestor = (props: EditAngelInvestorProps) => {
  const {
    selectedAngelInvestor,
    userAngelInvestorId,
    userType,
  } = props;
  const {
    getAngelInvestorById,
    updateAngelInvestorById,
  } = props.actions;

  const [form] = Form.useForm();

  const params: { id?: string } = useParams();
  const { id } = params;
  const angelInvestorId = useMemo(
    () => (identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : parseInt(id!, 10)),
    [id],
  );

  const { state, setAll, setField } = useEditAngelInvestorHook();

  const {
    imageLink,
  } = state;

  useEffect(() => {
    getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ angelInvestor: selectedAngelInvestor });
  }, [selectedAngelInvestor]);

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state]);

  const handleSubmit = (values: AngelInvestorType) => {
    updateAngelInvestorById({
      id: angelInvestorId,
      angelInvestor: {
        ...values,
        imageLink: state.imageLink,
      },
    });
  }

  const beforeLogoUpload = (file: RcFile, field: 'imageLink') => {
    setField({ field, value: file as Blob });
    return false;
  }

  return (
    <div className="edit-angelinvestor__container content-container">
      <Breadcrumb
        navigateTo={
          isAngelInvestor(userType)
            ? routes.companyProfileUrl
            : composeAngelInvestorDetailsUrl(angelInvestorId)
        }
        routeName="Angel investor Details"
      />
      <TitleWithActions title={selectedAngelInvestor?.name} />

      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="onChange"
        form={form}
        initialValues={state}
      >
        <div className="edit-angelinvestor__fields">
          <div className="edit-angelinvestor__section">
            <EditProfileImage imageLink={imageLink} beforeLogoUpload={beforeLogoUpload} />
          </div>

          <div className="edit-angelinvestor__section">
            {editAIFormElements.map((element) => {
              if (element.type === 'heading') {
                return <Input.Heading key={element.name} title={element.title} />
              }

              if (element.type === 'field' && element.component) {
                return (
                  <Input
                    key={element.name}
                    label={element.label}
                    name={element.name}
                    rules={element.rules}
                    required={element.required}
                  >
                    {element.component}
                  </Input>
                )
              }
              return null
            })}
          </div>

          <div className="edit-angelinvestor__section">
            <Button type="primary" htmlType="submit" className="primary-button">
              Update Angel investor
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EditAngelInvestor;
