import React, { useEffect } from 'react';
import { Button, Form } from 'antd';

import { ADGMLicense, addMissingDetailsHook } from 'components/AddMissingDetails';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseUploadFormProps } from 'types/startups';
import { identity } from 'utils';

import './ADGMLicenseUploadForm.scss';
import moment from 'moment'
import { ADGMLicenseType } from '../../../../packages/angel_investors_repository'
import { formatDateDashYMDTimeHMS } from '../../../utils/constants/formatters'

const ADGMLicenseUploadForm = (props: ADGMLicenseUploadFormProps) => {
  const [form] = Form.useForm();
  const {
    isEdit,
    setIsEdit,
    adgm,
    startupId,
    actions: {
      createStartupADGMLicense, updateADGMLicenseByStartupId, setIsDrawerOpen,
    },
  } = props;

  const {
    state,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  } = addMissingDetailsHook();

  const {
    adgmLicense,
    hasLicense,
    fileName,
  } = state;

  useEffect(() => {
    if (identity.isObjWithChildren(adgm) && identity.isTruthyString(adgm?.aDGMLicenseUrl)) {
      setFileName(adgm.aDGMLicenseUrl);
      setADGMAll(adgm);
      setHasLicense(true);
    }
  }, [adgm]);

  const handleSubmit = (values: ADGMLicenseType) => {
    const adgmLicensePayload = {
      ...values,
      expiryDate: moment(values.expiryDate).format(formatDateDashYMDTimeHMS),
      issueDate: moment(values.issueDate).format(formatDateDashYMDTimeHMS),
      acceptanceDate: moment(values.acceptanceDate).format(formatDateDashYMDTimeHMS),
      aDGMLicenseUrl: adgmLicense.aDGMLicenseUrl,
      startupId: adgmLicense.startupID,
      approvedRejectedStatus: adgmLicense.approvedRejectedStatus,
      id: adgm.id,
    }

    if (hasLicense) {
      if (isEdit) {
        updateADGMLicenseByStartupId({
          id: adgm.id,
          adgmLicense: adgmLicensePayload,
          startupId,
        });
      } else {
        createStartupADGMLicense({
          ...adgm,
          startupId,
        });
      }
    }
  };

  const onSetHasLicense = (value: DataPayload<boolean>) => {
    setHasLicense(value.data);
  }

  const onClose = () => (isEdit ? setIsEdit(false) : setIsDrawerOpen(false))

  const initialValues = {
    ...adgm,
    issueDate: identity.isTruthyString(adgm?.issueDate) ? moment(adgm?.issueDate) : null,
    expiryDate: identity.isTruthyString(adgm?.expiryDate) ? moment(adgm?.expiryDate) : null,
    acceptanceDate: identity.isTruthyString(adgm?.acceptanceDate) ? moment(adgm?.acceptanceDate) : null,
  }

  return (
    <div className="adgm-license-upload-form__container">
      <Form
        scrollToFirstError
        validateTrigger="onChange"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ADGMLicense
          fileName={fileName as string}
          hasLicense={hasLicense}
          adgmLicense={adgmLicense}
          setADGMField={setADGMField}
          isAddMissingDetails
          setHasLicense={onSetHasLicense}
          setADGMAll={setADGMAll}
        />

        <div
          className="drawer-buttons"
          style={{
            marginTop: 16,
            width: '100%',
          }}
        >
          <Button
            type="default"
            className="secondary-button"
            onClick={onClose}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={!hasLicense}
            className="primary-button"
            style={{ width: isEdit ? 180 : 160 }}
          >
            {`${isEdit ? 'Update' : 'Add'} ADGM License`}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ADGMLicenseUploadForm;
