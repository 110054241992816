// import { formatUrl } from 'packages/http_client/utils/converters';
import { identity } from 'utils';
import { technicalLicenseDef } from 'redux/utils';

import {
  TLHADGMLicenseType,
  CreateADGMLicenseApiRequestPayload,
  TLHCreateADGMLicensePayload,
  CreateIncentiveAgreementBodyType,
  CreateTechnicalLicensePayload,
  CreateTechnicalLicenseRequestPayload,
  EditTechnicalLicensePayload,
  GetInvestmentStagesApiResponseType,
  GetStagesApiResponseType,
  GetTechnicalLicensesApiResponseType,
  InvestmentStageResponseType,
  ResponseTechnicalLicenseType,
  StageResponseType,
  StageType,
  TechnicalLicenseQuery,
  TechnicalHolderType,
  UpdateAdgmLicenseByTechnicalLicenseIdRequestPayload,
  UpdateCreateIncentiveAgreementType,
  UpdateIncentiveAgreementBodyType,
  UpdateTechnicalLicenseRequestPayload,
} from '../types';
import { queryBuilder } from '../../http_client';

export const convertIncentiveAgreementBody = (data: UpdateCreateIncentiveAgreementType)
  : CreateIncentiveAgreementBodyType | UpdateIncentiveAgreementBodyType => ({
  _technical_license_id: data.technicalLicenseID,
  _start_date: data.startDate,
  _end_date: data.endDate,
  _file: data.file as Blob,
  _wallet_amount: data.walletAmount,
});

export const convertGetStagesResponse = (stage: StageResponseType): StageType => ({
  id: stage.id,
  name: stage.name,
  createdAt: stage.createdAt,
});

export const convertGetInvestmentStagesResponse = (stage: InvestmentStageResponseType): StageType => ({
  id: stage.id,
  name: stage.invStageName || stage.name,
});

export const convertCreateTechnicalLicenseRequestPayload = (
  payload: CreateTechnicalLicensePayload,
): CreateTechnicalLicenseRequestPayload => ({
  _name: payload.name,
  _location: payload?.location,
  _logo: payload.logo,
  _website: payload.website,
  _stage_id: payload.stage,
  _investment_stage_id: payload.investmentStage,
  _email: payload.email,
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _job_title: payload.jobTitle,
  _description: payload.description,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertCreateADMGLicenseRequestPayload = (payload: TLHCreateADGMLicensePayload):
CreateADGMLicenseApiRequestPayload => ({
  _adgm_license_no: payload.aDGMLicenseNo,
  _technical_license_id: payload.technicalLicenseId,
  _adgm_license_url: payload.aDGMLicenseUrl,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,

});

export const convertGetStagesListResponse = (response?: GetStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetStagesResponse(stage));
  }
}

export const convertGetInvestmentStagesListResponse = (response?: GetInvestmentStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetInvestmentStagesResponse(stage));
  }
}

export const convertCreateTechnicalLicenseApiId = (data?: number[]) => (
  identity.isFunction(data) ? data![0] : undefined);

export const convertGetTechnicalLicenseResponse = (technicalLicense?: ResponseTechnicalLicenseType):
TechnicalHolderType => {
  if (identity.isObjWithChildren(technicalLicense)) {
    return {
      id: technicalLicense?.id!,
      name: technicalLicense?.name!,
      website: technicalLicense?.website!,
      location: technicalLicense?.location!,
      about: technicalLicense?.description!,
      imageLink: technicalLicense?.logo!,
      walletID: technicalLicense?.walletID!,
      wallet: technicalLicense?.wallet!,
      status: technicalLicense?.status!,
      contactEmail: technicalLicense?.contactEmail!,
      aDGMApprovedStatus: technicalLicense?.aDGMApprovedStatus!,
      acceptanceLetterApprovedStatus: technicalLicense?.acceptanceLetterApprovedStatus!,
      incentiveAgreementApprovedStatus: technicalLicense?.incentiveAgreementApprovedStatus!,
      currentADGMLicense: technicalLicense?.currentADGMLicense!,
      currentADGMLicenseID: technicalLicense?.currentADGMLicenseID!,
      currentIncentiveAgreement: technicalLicense?.currentIncentiveAgreement,
      currentIncentiveAgreementID: technicalLicense?.currentIncentiveAgreementID!,
      acceptanceLetter: technicalLicense?.acceptanceLetter!,
      contactFirstName: technicalLicense?.contactFirstName!,
      contactLastName: technicalLicense?.contactLastName!,
      moUEndDate: technicalLicense?.moUEndDate!,
      moUSigned: technicalLicense?.moUSigned!,
      moUStartDate: technicalLicense?.moUStartDate!,
      contactPhone: technicalLicense?.contactPhone!,
      contactRole: technicalLicense?.contactRole!,
    };
  }
  return technicalLicenseDef;
}

export const convertGetAdGMLicenseResponse = (adgmLicense?: TLHADGMLicenseType): TLHADGMLicenseType => ({
  id: adgmLicense?.id!,
  aDGMLicenseNo: adgmLicense?.aDGMLicenseNo!,
  aDGMLicenseUrl: adgmLicense?.aDGMLicenseUrl!,
  technicalLicenseID: adgmLicense?.technicalLicenseID!,
  legalSignatoryName: adgmLicense?.legalSignatoryName!,
  legalSignatoryTitle: adgmLicense?.legalSignatoryTitle!,
  legalSignatoryEmail: adgmLicense?.legalSignatoryEmail!,
  legalADGMName: adgmLicense?.legalADGMName!,
  expiryDate: adgmLicense?.expiryDate!,
  issueDate: adgmLicense?.issueDate!,
  address: adgmLicense?.address!,
});

export const convertGetTechnicalLicenseListResponse = (
  response: GetTechnicalLicensesApiResponseType | undefined,
): TechnicalHolderType[] => {
  const data: TechnicalHolderType[] = [];
  if (identity.isFullArray(response?.result)) {
    response?.result!.forEach((technicalLicense: ResponseTechnicalLicenseType) => {
      const mappedTechnicalLicense = convertGetTechnicalLicenseResponse(technicalLicense);
      if (identity.isObjWithChildren(mappedTechnicalLicense)) {
        data.push(mappedTechnicalLicense!);
      }
    });
  }

  return data;
}

export const convertEditTechnicalLicenseRequestPayload = (payload: Partial<EditTechnicalLicensePayload>)
  : UpdateTechnicalLicenseRequestPayload => ({
  _name: payload?.name!,
  _website: payload?.website!,
  _description: payload?.about!,
  _stage_id: payload?.stage!,
  _logo: payload?.imageLink!,
  _location: payload.location!,
  _acceptance_letter_approved_status: payload.acceptanceLetterApprovedStatus,
  _adgm_approved_status: payload.aDGMApprovedStatus,
  _incentive_agreement_approved_status: payload.incentiveAgreementApprovedStatus,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertEditAdgmLicenseByTechnicalLicenseIdRequestPayload = (
  payload: TLHADGMLicenseType,
): UpdateAdgmLicenseByTechnicalLicenseIdRequestPayload => ({
  _adgm_license_no: payload?.aDGMLicenseNo!,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,
  _adgm_license_url: payload?.aDGMLicenseUrl!,
});

export const formatQuery = (query: TechnicalLicenseQuery) => {
  const {
    isPreload, limit, offset, statusID,
  } = query;
  const {
    locations, stage, capitalRaised, incentiveAmount, fieldSelection, searchString,
  } = query.filters || {};
  const joinTables = [];
  const params = queryBuilder({ stage_id: identity.isPositiveNumber(stage) ? stage : undefined, status_id: statusID });
  if (identity.isTruthyString(searchString)) {
    params.searchQuery(searchString!);
  }

  if (isPreload) {
    params.preload();
  }

  if (identity.isTruthyString(incentiveAmount) || identity.isTruthyString(capitalRaised)) {
    joinTables.push({ tableName: 'wallet_amount_utilizations', onId: 'wallet_id' });
  }

  params
    .limit(limit)
    .offset(offset)
    .select(fieldSelection)
    .in({ location: locations })
    .join(joinTables);
  return params.toObject();
}
