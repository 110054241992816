import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleNotifications, setNotificationsSeen } from 'redux/actions/notification';
import { selectNewCount, selectIsOpen } from 'redux/selectors/notifications';
import { selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';
import { getSearchResult, setNavigationSearchResults } from 'redux/actions/workspace';
import { selectSearchResults } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  searchResults: selectSearchResults(state),
  userType: selectUserType(state),
  notificationsOpen: selectIsOpen(state),
  newCount: selectNewCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getSearchResult,
    toggleNotifications,
    setNotificationsSeen,
    setNavigationSearchResults,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
