import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Form, Spin,
} from 'antd'
import { useLocation, useParams } from 'react-router';
import { RcFile } from 'antd/lib/upload';
import classnames from 'classnames';

import { EditServiceProviderPayload } from 'packages/service_repository';
import {
  identity,
  isStartup,
  isEitherAdmin,
  isAngelInvestor,
} from 'utils';
import { StartupLogo } from 'components/EditStartup/components';
import {
  EditProfileImage,
  RenderIf,
  TitleWithActions,
  Breadcrumb,
  Input,
  CompanyDeckFile,
} from 'components/components';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { EditServiceProviderProps } from 'types/serviceProviders/editServiceProvider';
import routes from 'routes';
import {
  ServiceProviderDetailsForm,
  ContactPersonForm,
  ServiceProviderOffers,
  ServiceProviderPerksForm,
} from './components';
import { defaultValues } from './utils/constants';

import './EditServiceProvider.scss';

const EditServiceProvider = (props: EditServiceProviderProps) => {
  const {
    serviceProvider, userType, isLoading, userServiceProviderId,
  } = props;
  const [form] = Form.useForm();
  const params: { id?: string } = useParams();
  const location = useLocation();

  const [logo, setLogo] = useState<string | Blob>(serviceProvider?.imageLink);
  const [isAvailableForAI, setIsAvailableForAI] = useState<boolean>(false);

  const { getServiceProviderById, patchServiceProviderDataById } = props.actions;
  const { id } = params;

  const isEditCompanyProfile = useMemo(() => (
    identity.isTruthyNumber(userServiceProviderId) && location.pathname === routes.editCompanyProfileUrl
  ), [location, userServiceProviderId]);

  const serviceProviderId = useMemo(() => (
    isEditCompanyProfile ? userServiceProviderId : parseInt(id!, 10)
  ), [id])
  /// Temporarily hiding offers for now

  const showOffer = false;

  const onSubmit = (data: EditServiceProviderPayload) => {
    const formData = {
      role: data?.role,
      about: data?.about,
      email: data?.email,
      logo: identity.isEmptyString(logo) ? serviceProvider?.imageLink : logo,
      firstName: data?.firstName,
      lastName: data?.lastName,
      websiteLink: data?.websiteLink,
      phoneNumber: data?.phoneNumber,
      isAvailableForAICommunity: data?.isAvailableForAICommunity,
    }

    patchServiceProviderDataById({ id: serviceProviderId, data: formData });
  };

  const handleIsAvailableForAICommunity = (data: boolean) => {
    form.setFieldValue('isAvailableForAICommunity', data);
    setIsAvailableForAI(data);
  };

  const beforeLogoUpload = (file: RcFile) => {
    setLogo(file as Blob);
    return false;
  }

  useEffect(() => {
    getServiceProviderById({ id: serviceProviderId });
  }, [id]);

  useEffect(() => {
    if (!serviceProvider.id) {
      return;
    }

    setIsAvailableForAI(serviceProvider?.isAvailableForAICommunity)

    form.setFieldsValue({
      ...serviceProvider,
    });
  }, [serviceProvider, form])

  if (!serviceProvider.id) {
    return null;
  }

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div
          className={classnames({
            'edit-service-provider': (isEitherAdmin(userType) || isEditCompanyProfile),
            'service-provider-more-details': !(isEitherAdmin(userType) || isEditCompanyProfile),
          })}
        >
          <Breadcrumb
            navigateTo={isEditCompanyProfile
              ? routes.companyProfileUrl
              : composeServiceProviderDetailsUrl(serviceProviderId)}
            routeName="service provider details"
          />

          <TitleWithActions title={serviceProvider?.name} />

          <div className="logo-container">
            <RenderIf condition={isEitherAdmin(userType) || isEditCompanyProfile}>
              <EditProfileImage imageLink={serviceProvider?.imageLink} beforeLogoUpload={beforeLogoUpload} />
            </RenderIf>

            <RenderIf condition={isStartup(userType) || isAngelInvestor(userType)}>
              <StartupLogo
                imageUrl={serviceProvider?.imageLink}
                name="imageLink"
              />
            </RenderIf>
          </div>

          <Form
            className="form-builder  edit-service-provider-form"
            onFinish={onSubmit}
            form={form}
            initialValues={defaultValues}
            key={serviceProvider?.id || 'form-key'}
          >
            <ServiceProviderDetailsForm userType={userType} />

            <Input
              label="Available For Angel Investors"
              layout="horizontal"
              name="isAvailableForAICommunity"
            >
              <Input.Switch
                checked={isAvailableForAI}
                onChange={handleIsAvailableForAICommunity}
              />
            </Input>

            <Input label="Rate Card Document" layout="horizontal" name="companyDeck">
              <CompanyDeckFile fileContent={serviceProvider?.companyDeck} />
            </Input>

            <Input label="Agreement Document" layout="horizontal" name="companyDeck">
              <CompanyDeckFile
                fileContent={serviceProvider?.agreementDocument}
                fileName="Agreement Document.pdf"
              />
            </Input>

            <ContactPersonForm userType={userType} />

            <RenderIf condition={isStartup(userType)}>
              <ServiceProviderPerksForm userType={userType} />
            </RenderIf>

            <RenderIf condition={isEitherAdmin(userType) || isEditCompanyProfile}>
              <>
                <RenderIf condition={showOffer}>
                  <ServiceProviderOffers />
                </RenderIf>
                <Button htmlType="submit" className="primary-button">Submit</Button>
              </>
            </RenderIf>
          </Form>
        </div>
      </Spin>
    </div>
  )
}

export default EditServiceProvider;
