import React from 'react';
import { identity, DRAWERS } from 'utils';
import { Spin, Space } from 'antd';
import { AboutProps } from 'types/componentTypes/AboutProps';
import {
  RenderIf,
} from 'components/components';
import { ViewUploadButton } from 'components/EditStartup/components';

import './About.scss';

const About = (props: AboutProps) => {
  const {
    title,
    description,
    isStartup,
    startupDetails,
    status,
  } = props;

  const handleOpenDrawer = (drawerBody: string) => {
    props.actions.setIsDrawerOpen({ isDrawerOpen: true });
    props.actions.setDrawerBody({ drawerBody });
  }

  if (props.isLoading) {
    return (
      <Space align="center" direction="vertical">
        <Spin />
      </Space>
    );
  }

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>

        <div className="about__card">
          { description || 'No description or details specified' }
          { props.children }
        </div>

        <RenderIf condition={isStartup}>
          <div className="about__details">
            <div className="details_row">
              <h4>Website:</h4>
              <p>{startupDetails?.website}</p>
            </div>

            {/* Acceptance Letter Button */}
            <div className="details_row">
              <h4>Acceptance Letter:</h4>
              <div className="acceptance-letter">
                <ViewUploadButton
                  userType="Angel investor"
                  name="Acceptance Letter"
                  onClick={() => handleOpenDrawer(DRAWERS.acceptanceLetterDetails)}
                  condition
                  status={startupDetails?.acceptanceLetterApprovedStatus}
                  disabled={!status}
                  title={status ? '' : 'Acceptance Letter has not been generated yet. Please check back later.'}
                />
              </div>
            </div>

            {/* ADGM License Button */}
            <div className="details_row">
              <h4>ADGM License:</h4>
              <div>
                <ViewUploadButton
                  userType="Startup team member"
                  name="ADGM License"
                  onClick={() => handleOpenDrawer(DRAWERS.startupAdgmLicenseDetails)}
                  condition
                  status={startupDetails?.aDGMApprovedStatus}
                  disabled={!(status >= 3) || status === 11}
                  title={status >= 3 ? '' : 'Acceptance Letter must be signed first.'}
                />
              </div>
            </div>

            {/* Incentive Agreement Button */}
            <div className="details_row">
              <h4>Incentive Agreement:</h4>
              <div>
                <ViewUploadButton
                  userType="Startup team member"
                  name="Incentive Agreement"
                  onClick={() => handleOpenDrawer(DRAWERS.incentiveAgreementDetails)}
                  condition
                  status={startupDetails?.currentIncentiveAgreement?.licenseAgreementStatus}
                  disabled={!(status >= 5) || status === 11}
                  title={status >= 5 ? '' : 'ADGM License must be submitted and approved.'}
                />
              </div>
            </div>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

export default About;
