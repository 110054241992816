import { composeServiceOverview, composeServiceProviderDetailsUrl, composeStartupDetailsUrl } from 'routes/routeComposers';
import ROLES from 'utils/constants/roles';
import { isProduction } from 'utils/helpers/environment';
import { DRAWERS } from '../../../../../utils';

// this is the default selected key for dropdown menu in navbar
export const selectedKey = '4';

const member = {
  key: '1',
  label: 'member',
  drawer: DRAWERS.addMember,
};

const startup = {
  key: '2',
  label: 'startup',
  drawer: DRAWERS.addStartup,
};

const angelInvestor = {
  key: '3',
  label: 'angel investor',
  drawer: DRAWERS.addAngelInvestor,
};

const partner = {
  key: '4',
  label: 'partner',
  drawer: DRAWERS.addPartner,
};

const partnerMember = {
  key: '5',
  label: 'partner member',
  drawer: DRAWERS.addPartnerMember,
};

const serviceProvider = {
  key: '6',
  label: 'service provider',
  drawer: DRAWERS.addServiceProvider,
};

const event = {
  key: '7',
  label: 'event',
  drawer: DRAWERS.addEvent,
};

const service = {
  key: '8',
  label: 'service',
  drawer: DRAWERS.addService,
}

const startupMember = {
  key: '9',
  label: 'member',
  drawer: DRAWERS.addMemberStartup,
};

const adminItemsTesting = [member, startup, angelInvestor, partner, partnerMember, serviceProvider, service, event];
const adminItemsProduction = [member, startup, angelInvestor, serviceProvider, service];
const adminItems = isProduction ? adminItemsProduction : adminItemsTesting;

export const items = {
  [ROLES.superAdmin]: adminItems,
  [ROLES.admin]: adminItems,
  [ROLES.startupFounder]: [startupMember],
  [ROLES.startupTeamMember]: [startupMember],
  [ROLES.serviceProvider]: [],
  [ROLES.employee]: [],
  '': [],
};

export const itemsMap = [
  member,
  startup,
  angelInvestor,
  partner,
  partnerMember,
  serviceProvider,
  event,
  service,
  startupMember,
];

export const SEARCH_RESULT_TYPE_COMPOSE_FUNCTION: {[key: string]: Function} = {
  startup: composeStartupDetailsUrl,
  serviceprovider: composeServiceProviderDetailsUrl,
  service: composeServiceOverview,
}
