import React, { useEffect } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { AngelInvestorsDropdownFiltersProps } from 'types/angelInvestors';
import { Input } from 'components/components';
import { useAngelInvestorsFiltersHook, approvedRejectedOptions } from '../../utils';
import { identity } from '../../../../utils'

const AngelInvestorsDropdownFilters = (props: AngelInvestorsDropdownFiltersProps) => {
  const {
    setShowDropdown,
    filters,
    sectorOptions,
  } = props;
  const {
    setAngelInvestorFilters,
    getAngelInvestorSectors,
  } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(sectorOptions)) {
      getAngelInvestorSectors();
    }
  }, []);

  const {
    state,
    initialState,
    resetFilters,
    setFilters,
    setAcceptanceLetter,
    setIncentiveAgreement,
    setSector,
  } = useAngelInvestorsFiltersHook();

  const { acceptanceLetter, incentiveAgreement, sector } = state;

  const onClose = () => {
    setFilters(filters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setAngelInvestorFilters(initialState);
    onClose();
  };

  const onFilter = () => {
    setAngelInvestorFilters(state);
    onClose();
  };

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>

      <Input label="Primary sector" layout="vertical">
        <Input.Select
          showSearch
          options={sectorOptions}
          value={sector}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setSector({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />

      <Input label="Acceptance Letter Approved Status" layout="vertical">
        <Input.Radio
          values={approvedRejectedOptions}
          value={acceptanceLetter}
          onChange={({ target: { value: data } }) => setAcceptanceLetter({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />

      <Input label="Incentive Agreement Approved Status" layout="vertical">
        <Input.Radio
          values={approvedRejectedOptions}
          value={incentiveAgreement}
          onChange={({ target: { value: data } }) => setIncentiveAgreement({ data })}
        />
      </Input>

      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default AngelInvestorsDropdownFilters;
