import React, {
  Ref, useEffect, useRef, useState,
} from 'react';
import { Button } from 'antd';

import { RequestItemCardProps } from 'types/requests';
import { Input, RenderIf } from 'components/components';
import { identity, parseNumberToPrice, positiveNumbersOnly } from 'utils';
import { CardRow, SpecialServiceRequestDetails } from './components';
import { PRICE_QUANTITY, PRICE_TYPE_TAG } from '../../../../utils';

import './RequestItemCard.scss';
import showNotification from '../../../../../../../../services/utils/showNotification'

const RequestItemCard = (props: RequestItemCardProps) => {
  const { item, editable, actions } = props;
  const {
    price,
    rate,
    priceType,
    quantity,
    service,
    id,
    serviceRequestId,
    details,
    servicePrice,
    fee,
    icp,
  } = item;

  const [modify, setModify] = useState(false);

  const rateRef = useRef<HTMLInputElement>();
  const priceRef = useRef<HTMLInputElement>();

  const handleUpdate = () => {
    const rateValue = Number(rateRef.current?.value);
    const priceValue = Number(priceRef.current?.value);

    if (rate && rateValue > rate) {
      showNotification(`Rate must not exceed ${rate}`, true, 400);

      return;
    }

    if (identity.isTruthyNumber(rateValue) || identity.isTruthyNumber(priceValue)) {
      actions.updateRequestItemById({
        data: {
          id,
          rate: rateValue,
          price: priceValue || price,
          serviceRequestId,
          servicePrice: servicePrice?.toPrice!,
          quantity: quantity!,
          details,
        },
      });
    }
  }

  useEffect(() => {
    setModify(false);
  }, [price]);

  return (
    <div className="request-item-card">
      <h1 className="request-item-card__title">{ service?.name }</h1>
      <RenderIf condition={identity.isTruthyString(PRICE_TYPE_TAG[priceType!])}>
        <CardRow label="Pricing option" value={PRICE_TYPE_TAG[priceType!]} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.officeType) && identity.isTruthyNumber(details?.noOfDesk)}>
        <CardRow label="No of Desk" value={details?.noOfDesk!} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.officeType) && details?.noOfDesk === undefined}>
        <CardRow label="No of Desk" value={1} />
      </RenderIf>
      <CardRow
        label="Quantity"
        value={identity.isTruthyString(PRICE_QUANTITY[priceType!]) ? `${quantity} ${PRICE_QUANTITY[priceType!]}` : `${quantity}`}
      />

      {rate ? (
        <>
          <div className="request-item-card__row">
            <p className="request-item-card__row__label">
              Rate
            </p>
            <div className="dot" />
            {modify
              ? (
                <div>
                  <Input
                    rules={[
                      positiveNumbersOnly,
                    ]}
                    required
                  >
                    <Input.InputNumber
                      min={0}
                      ref={rateRef as Ref<HTMLInputElement>}
                      defaultValue={rate}
                      type="number"
                    />
                  </Input>
                </div>
              )
              : (
                <p className="request-item-card__row__item">
                  {parseNumberToPrice(rate, 'AED')}
                </p>
              )}
          </div>

          <CardRow
            label="Insurance Fee"
            value={parseNumberToPrice(fee, 'AED')}
          />

          <CardRow
            label="ICP"
            value={parseNumberToPrice(icp, 'AED')}
          />
        </>
      ) : (
        <div className="request-item-card__row">
          <p className="request-item-card__row__label">
            {identity.isTruthyString(priceType) ? `Price per ${priceType?.split('-')[1]}` : 'Unit Price'}
          </p>
          <div className="dot" />
          {modify
            ? (
              <Input rules={[positiveNumbersOnly]} required>
                <Input.InputNumber
                  min={0}
                  ref={priceRef as Ref<HTMLInputElement>}
                  defaultValue={price / quantity!}
                  type="number"
                />
              </Input>
            )
            : (
              <p className="request-item-card__row__item">
                {parseNumberToPrice(price / quantity!, 'AED')}
              </p>
            )}
        </div>
      )}

      <CardRow label="Total" value={parseNumberToPrice(Number(price), 'AED')} bold />
      <SpecialServiceRequestDetails details={details} service={service} serviceRequestId={serviceRequestId} />
      <RenderIf condition={editable!}>
        <div className="request-item-card__buttons">
          {modify ? (
            <>
              <Button className="secondary-button" onClick={() => setModify(false)}>Discard changes</Button>
              <Button className="primary-button" onClick={handleUpdate}>Update request</Button>
            </>
          ) : (
            <Button className="secondary-button" onClick={() => setModify(true)}>
              Modify request
            </Button>
          )}
        </div>
      </RenderIf>
    </div>
  )
}

RequestItemCard.defaultProps = {
  editable: false,
}

export default RequestItemCard;
