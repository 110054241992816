/* eslint-disable no-nested-ternary,@typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import {
  Card, Row, Col,
} from 'antd';
import classnames from 'classnames';

import { CardProps } from 'types/componentTypes/CardProps';
import {
  isStartup,
} from 'utils'
import { CardCover } from '../Card';

import '../Card/Card.scss';
import { CardInfoRowContent } from '../Card/components'

const GridCard = (props: CardProps) => {
  const {
    columns,
    dataSource,
    onGridCardClick,
    numOfCard,
    component,
  } = props;
  const isPeople = useMemo(
    () => columns && columns.length > 3 && columns[3].key === 'nationality',
    [columns],
  );

  const sliceNumber = numOfCard || dataSource?.length;

  const handleOnGridCardClick = (data: any) => onGridCardClick(data)

  return (
    <Row gutter={16}>
      {dataSource?.slice(0, sliceNumber).map((data, index) => (
        <Col xs={16} sm={14} md={10} xl={8} xxl={6} key={(data?.id || index) as React.Key}>
          <div className="card-container">
            <Card
              bordered
              cover={<CardCover isPeople={!!isPeople} data={data} component={component} />}
              className={classnames('card', { 'card-pointer': !isStartup(props.userType) })}
              onClick={() => handleOnGridCardClick(data)}
            >
              <div
                className={classnames({ 'service-inactive': ['services', 'startup-services', 'angel-investor-services'].includes(component || '') && !data.active })}
              >
                {columns?.map((column, columnIndex) => {
                  if (column?.key === 'status') {
                    return null;
                  }

                  const description = column.render && column.render(data, data, columnIndex)

                  return (
                    <div className="category-container" key={column?.key}>
                      <CardInfoRowContent
                        title={`${column.title}`}
                        description={String(description) || ''}
                      />
                    </div>
                  )
                })}
              </div>

              {data.status && (
                <div className="category-container">
                  <CardInfoRowContent
                    isRounded
                    title="Status"
                    description={String(data.status.name) || ''}
                  />
                </div>
              )}
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default GridCard;
