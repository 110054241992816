import React, { useEffect } from 'react';
import { Form, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { CreateAngelInvestorPayload } from 'packages/angel_investors_repository';
import { FormBuilder } from 'components/components';
import { AddAngelInvestorDrawerProps } from 'types/angelInvestors/addAngelInvestor';
import {
  defaultValues,
  addAIFormFields,
  addAIFounderFields,
  addAIWalletFields,
  addAIDocumentsTemplatesFields,
  AddAIFieldsCreatorParams,
} from './utils';
import Title from '../components/TitleWithActions/components/Title';

import './AddAIDrawer.scss'

const AddAIDrawer = (props: AddAngelInvestorDrawerProps) => {
  const {
    emailTemplates,
    incentiveTemplates,
    actions: {
      createAngelInvestor,
      getEmailTemplates,
      getIncentiveTemplates,
    },
  } = props;

  const [form] = Form.useForm();

  const handleSubmit = (data: CreateAngelInvestorPayload) => {
    createAngelInvestor({ data });
  }

  const beforeLogoUpload = (file: RcFile) => {
    const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!validFormats.includes(file.type)) {
      form.setFields([
        {
          name: 'logo',
          errors: ['Upload a file in .png, .jpg, or .jpeg format'],
        },
      ]);
      return Upload.LIST_IGNORE;
    }

    form.setFields([
      {
        name: 'logo',
        errors: [],
      },
    ]);

    form.setFieldValue('logo', file as Blob);
    return false;
  };

  const AIFieldsCreateData: AddAIFieldsCreatorParams = {
    beforeLogoUpload,
  };

  const AIDocumentsFieldsCreateData = {
    emailTemplates: emailTemplates?.map(({ id, title }) => ({ label: title, value: id })),
    incentiveTemplates: incentiveTemplates?.map(({ id, title }) => ({ label: title, value: id })),
  }

  useEffect(() => {
    getEmailTemplates();
    getIncentiveTemplates();
  }, []);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="add-ai-drawer form-standalone"
      initialValues={defaultValues}
      onFinishFailed={scrollToFirstError}
    >
      <Title title="Angel Investor Details" size="m" />
      <FormBuilder formFragment fields={addAIFormFields(AIFieldsCreateData)} />

      <Title title="Founder Details" size="m" />
      <Title title="Note: An activation link to the portal will be sent to the Email entered below." size="xs" />
      <FormBuilder formFragment fields={addAIFounderFields} />

      <Title title="Wallet" size="m" />
      <FormBuilder formFragment fields={addAIWalletFields} />

      <Title title="Documents Templates" size="m" />
      <Title title="Note: Angel Investor's details will be automatically filled in by the System." size="xs" />
      <FormBuilder formFragment fields={addAIDocumentsTemplatesFields(AIDocumentsFieldsCreateData)} />

      <DrawerButton>Add angel investor</DrawerButton>
    </Form>
  )
}

export default AddAIDrawer;
