import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  DRAWERS,
  getOffset,
  getRowKey,
  ROLES,
} from 'utils';
import config from 'assets/config';
import { ServiceProviderType } from 'types/serviceProviders';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { gridPaginationOptions, GridTable } from 'components/components'
import { ServicesProps, ServicesType } from 'types/services';
import { servicesColumns } from '../../../utils/constants/columns';

const Services = (props: ServicesProps) => {
  const {
    userType,
    requests,
    services,
    unsentRequests,
    limit,
    setCurrentPage,
    servicesCount,
    setLimit,
    currentPage,
    filters,
    setFiltersChanged,
    filtersChanged,
    serviceSubCategory,
  } = props;

  const {
    setDrawerBody,
    setIsDrawerOpen,
    getServiceById,
    getServices,
    deleteService,
    getServicesCount,
  } = props.actions;

  const history = useHistory();

  useEffect(() => {
    if (!gridPaginationOptions.includes(limit)) {
      setLimit(config.GRID_TABLE_DEFAULT_LIMIT);
    }
  }, []);

  useEffect(() => {
    setFiltersChanged(true);
  }, [filters]);

  useEffect(() => {
    getServices({
      isPreload: true,
      limit,
      offset: getOffset(limit, filtersChanged ? 1 : currentPage),
      filters: { ...filters, serviceSubCategory },
    });
    if (filtersChanged) {
      getServicesCount({ filters: { ...filters, serviceSubCategory } });
      setCurrentPage(1);
    }
    setFiltersChanged(false);
  }, [limit, filters, currentPage, serviceSubCategory]);

  const handleRequestService = (record: ServicesType) => {
    setDrawerBody({ drawerBody: DRAWERS.requestService })
    setIsDrawerOpen({ isDrawerOpen: true });
    getServiceById({ id: record.id });
  }

  const handleDelete = (record: ServicesType) => {
    deleteService({ id: record.id });
  }

  const openServiceDrawer = (record: ServicesType) => {
    setDrawerBody({ drawerBody: DRAWERS.editService });
    setIsDrawerOpen({ isDrawerOpen: true });
    getServiceById({ id: record.id });
  }

  switch (userType) {
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      return (
        <GridTable
          dataSource={services}
          columns={servicesColumns}
          rowKey={getRowKey(services, 'services', 'id')}
          component="startup-services"
          onGridCardClick={() => null}
          onRequestService={handleRequestService}
          unsentRequests={unsentRequests}
          showCustomPagination
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          total={servicesCount}
        />
      );
    }
    case ROLES.angelInvestor: {
      return (
        <GridTable
          dataSource={services}
          columns={servicesColumns}
          rowKey={getRowKey(services, 'services', 'id')}
          component="angel-investor-services"
          onGridCardClick={() => null}
          onRequestService={handleRequestService}
          unsentRequests={unsentRequests}
          showCustomPagination
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          total={servicesCount}
        />
      );
    }
    case ROLES.serviceProvider: {
      return (
        <GridTable
          columns={servicesColumns}
          dataSource={services}
          rowKey={getRowKey(services, 'services', 'id')}
          component="services"
          onDeleteService={handleDelete}
          onEditService={openServiceDrawer}
          onGridCardClick={openServiceDrawer}
          onRequestService={handleRequestService}
          unsentRequests={props.unsentRequests}
          requests={requests}
          showCustomPagination
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          total={servicesCount}
        />
      );
    }
    case ROLES.superAdmin:
    case ROLES.admin: {
      return (
        <GridTable
          columns={servicesColumns}
          dataSource={services}
          rowKey={getRowKey(services, 'services', 'id')}
          component="startup-sp"
          onGridCardClick={openServiceDrawer}
          displayLogoImage={false}
          firstButtonTitle="View profile"
          secondButtonTitle="Edit service"
          firstButtonAction={
            (record: ServiceProviderType) => history.push(composeServiceProviderDetailsUrl(record?.id!))
          }
          secondButtonAction={openServiceDrawer}
          showCustomPagination
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          total={servicesCount}
        />
      );
    }
    default: {
      return null;
    }
  }
}

export default Services;
