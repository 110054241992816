import React from 'react';
import { Input as AntdInput, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const content = (
  <ul>
    <li>
      The password must be at least
      {' '}
      <strong>8 characters long</strong>
      .
    </li>
    <li>
      It should contain at least
      {' '}
      <strong>one uppercase letter</strong>
      {' '}
      (A-Z).
    </li>
    <li>
      It should contain at least
      {' '}
      <strong>one lowercase letter</strong>
      {' '}
      (a-z).
    </li>
    <li>
      It should include at least
      {' '}
      <strong>one digit</strong>
      {' '}
      (0-9).
    </li>
    <li>
      It must contain at least
      {' '}
      <strong>one special character</strong>
      {' '}
      (e.g., !@#$%^&*).
    </li>
  </ul>
);

const Icon = () => (
  <Popover
    placement="top"
    title="Password Requirements"
    content={content}
  >
    <InfoCircleOutlined />
  </Popover>
)

const Password = (props: any) => {
  const { id, value, onChange } = props;

  return (
    <AntdInput.Password
      prefix={<Icon />}
      id={id}
      value={value}
      onChange={onChange}
    />
  )
}

export default Password;
