import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectIsAuthenticated,
  selectStartupStatus,
  selectUserType,
  selectAngelInvestorStatus,
  selectTechnicalHolderStatus,
  selectIsBlocked,
} from 'redux/selectors/auth';
import {
  selectToggleUserProfile,
} from 'redux/selectors/users';
import { selectIsOpen } from 'redux/selectors/notifications';
import { toggleNotifications } from 'redux/actions/notification';
import { toggleUserProfile } from 'redux/actions/users';

const mapStateToProps = (state: StateType) => ({
  isAuthenticated: selectIsAuthenticated(state),
  isOpen: selectIsOpen(state),
  startupStatus: selectStartupStatus(state),
  angelInvestorStatus: selectAngelInvestorStatus(state),
  technicalHolderStatus: selectTechnicalHolderStatus(state),
  userType: selectUserType(state),
  isVisible: selectToggleUserProfile(state),
  isBlocked: selectIsBlocked(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    toggleNotifications,
    toggleUserProfile,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
