import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';

import { setIsDrawerOpen } from 'redux/actions/workspace';
import {
  updateStartupById,
} from 'redux/actions/startups';

import {
  selectStartupDetails,
} from 'redux/selectors/startups';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  info: selectStartupDetails(state),
  userType: selectUserType(state),
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateStartupById,
    setIsDrawerOpen,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
