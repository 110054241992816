import { createSelector } from 'reselect'
import { AuthStateType, UserType } from 'types/auth'
import { StateType } from 'types'
import { NOTICE_LEVEL } from 'utils/constants/noticeModal'

const selectAuth = (state: StateType): AuthStateType => state.authReducer;

export const selectMyProfileData = createSelector(
  selectAuth,
  (state: AuthStateType) => state.myProfile,
);

export const selectMyProfileId = createSelector(
  selectMyProfileData,
  (state) => state?.id,
);

export const selectUser = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user,
);

export const selectPartnerId = createSelector(
  selectUser,
  (state: UserType) => state.partnerId,
);

export const selectPartner = createSelector(
  selectAuth,
  (state: AuthStateType) => state.partner,
);

export const selectStartupStatus = createSelector(
  selectAuth,
  (state: AuthStateType) => state.startupStatus,
);

export const selectAngelInvestorStatus = createSelector(
  selectAuth,
  (state: AuthStateType) => state.angelInvestorStatus,
);

export const selectTechnicalHolderStatus = createSelector(
  selectAuth,
  (state: AuthStateType) => state.technicalHolderStatus,
);

export const selectUserType = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.userType,
);

export const selectUsername = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.userName,
);

export const selectIsAuthenticated = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isAuthenticated,
);

export const selectIsLoading = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isLoading,
);

export const selectIsAuthenticationChecked = createSelector(
  selectAuth,
  (state: AuthStateType) => state.isAuthenticationChecked,
);

export const selectUserStartupId = createSelector(
  selectUser,
  (state: UserType) => state.startupId,
);

export const selectUserAngelInvestorId = createSelector(
  selectUser,
  (state: UserType) => state.angelInvestorId,
);

export const selectUserId = createSelector(
  selectUser,
  (state: UserType) => parseInt(state.userId, 10),
);

export const selectUserTechnicalLicenseId = createSelector(
  selectUser,
  (state: UserType) => state.technicalLicenseId,
);

export const selectUserServiceProviderId = createSelector(
  selectUser,
  (state: UserType) => state.serviceProviderId,
);

export const selectServiceProvider = createSelector(
  selectAuth,
  (state: AuthStateType) => state.serviceProvider,
)

export const selectUserStartup = createSelector(
  selectAuth,
  (state: AuthStateType) => state.startup,
);

export const selectUserAngelInvestor = createSelector(
  selectAuth,
  (state: AuthStateType) => state.angelInvestor,
);

export const selectIsBlocked = createSelector(
  selectAuth,
  (state: AuthStateType) => {
    const isBlockedStatusId = [8, 9];

    if (
      state.user.serviceProviderId
      || state.user.serviceProviderId
      || state.user.technicalLicenseId
    ) {
      return false
    }

    if (state?.angelInvestor?.status.id === -1 && state?.startup?.status.id === -1) {
      return;
    }

    return isBlockedStatusId.includes(state?.angelInvestor?.status.id || 0)
      || isBlockedStatusId.includes(state?.startup?.status.id || 0);
  },
)

export const selectUserTechnicalLicense = createSelector(
  selectAuth,
  (state: AuthStateType) => state.technicalLicense,
);

export const selectUserNotice = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.notice,
);

export const selectIsBlockedByNotice = createSelector(
  selectAuth,
  (state: AuthStateType) => state.user.notice?.level === NOTICE_LEVEL.blocked,
);
