import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { getIncentiveAgreementById, updateStartupById } from 'redux/actions/startups';
import {
  selectIncentiveAgreement,
  selectStartupId,
  selectIncentiveAgreementStatus,
  selectStartupDetails,
} from 'redux/selectors/startups';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  info: selectStartupDetails(state),
  incentiveAgreement: selectIncentiveAgreement(state),
  userType: selectUserType(state),
  startupID: selectStartupId(state),
  incentiveAgreementStatus: selectIncentiveAgreementStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getIncentiveAgreementById,
    updateStartupById,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
