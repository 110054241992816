import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import routes from 'routes';
import { Drawer } from 'components/components';
import { AppType } from 'types/AppType';
import { ROLES } from 'utils';
import LayoutWithSidebar from '../LayoutWithSidebar';
import ResetPassword from '../ResetPassword';
import Login from '../Login';
import AddAIMissingDetailsWithNavbar from './components/AddAIMissingDetailsWithNavbar/View';
import AddTLHDetailsWithNavbar from './components/AddTLHDetailsWithNavbar'
import { StartupRejected, AddMissingDetailsWithNavbar, Forbidden } from './components';

import './App.scss';

const App = (props: AppType) => {
  const { location, userType, actions } = props;

  useEffect(() => {
    if (userType) {
      return;
    }

    actions.checkIsUserAuthenticated();
  }, [userType]);

  return (
    <div className="App">
      <Switch location={location}>
        <Route path={routes.loginUrl} exact component={Login} />
        <Route
          path={routes.resetPasswordUrl}
          exact
          component={ResetPassword}
        />
        <Route
          path={routes.addMissingDetailsUrl}
          exact
          component={
            userType === 'Angel investor'
              ? AddAIMissingDetailsWithNavbar : AddMissingDetailsWithNavbar
          }
        />
        {userType === ROLES.technicalLicense && (
          <Route
            path={routes.addMissingDetailsUrl}
            exact
            component={AddTLHDetailsWithNavbar}
          />
        )}
        <Route
          path={routes.startupRejectedUrl}
          exact
          component={StartupRejected}
        />
        <Route
          path={routes.forbidden}
          exact
          component={Forbidden}
        />
        <LayoutWithSidebar />
      </Switch>
      <Drawer />
    </div>
  );
}

export default withRouter(App);
