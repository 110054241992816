/* eslint-disable no-nested-ternary,@typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import {
  Card, Row, Col, Button, Tooltip,
} from 'antd';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import { CardProps } from 'types/componentTypes/CardProps';
import {
  isStartup,
  isAngelInvestor,
  identity,
  disableRequestButton,
  showDeleteConfirm,
  showRequestedButton,
  isCommunity,
} from 'utils';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { RenderIf } from '..';
import { DescriptionBox, CardInfoRow, CardCover } from './components';

import './Card.scss';

const GridCard = (props: CardProps) => {
  const {
    columns,
    dataSource,
    onGridCardClick,
    numOfCard,
    component,
    onDeleteService,
    onEditService,
    onRequestService,
    unsentRequests,
    startupStatus,
    angelInvestorStatus,
  } = props;
  const history = useHistory();

  const isPeople = useMemo(
    () => columns && columns.length > 3 && columns[3].key === 'nationality',
    [columns],
  );

  const sliceNumber = numOfCard || dataSource?.length;

  const displayCover = component !== 'services'
    && component !== 'startup-services'
    && component !== 'angel-investor-services';

  const handleOnGridCardClick = (data: any) => onGridCardClick(data)

  const communityStatus = isStartup(props.userType) ? !isCommunity(startupStatus) : !isCommunity(angelInvestorStatus)

  return (
    <Row gutter={16}>
      {dataSource?.slice(0, sliceNumber).map((data, index) => (
        <Col xs={16} sm={14} md={10} xl={8} xxl={6} key={(data?.id || index) as React.Key}>
          <div className="card-container">
            <Card
              bordered
              cover={displayCover ? <CardCover isPeople={!!isPeople} data={data} component={component} /> : null}
              className={classnames('card', { 'card-pointer': !isStartup(props.userType) })}
              onClick={() => handleOnGridCardClick(data)}
            >
              <div className={classnames({ 'service-inactive':  ['services', 'startup-services', 'angel-investor-services'].includes(component || '') && !data.active })}>
                <RenderIf
                  condition={
                    component === 'services'
                    || component === 'startup-services'
                    || component === 'angel-investor-services'
                  }
                >
                  <p className="title">{identity.isTruthyString(data.name) ? data.name : NOT_SPECIFIED}</p>
                </RenderIf>
                <RenderIf condition={['startup-services', 'angel-investor-services'].includes(component || '')}>
                  <p className="subtitle">{`by ${data.serviceProvider?.name || NOT_SPECIFIED}`}</p>
                </RenderIf>
                <RenderIf condition={!isPeople}>
                  <DescriptionBox
                    title="Description"
                    description={identity.isTruthyString(data.about) ? data.about : 'No description available'}
                  />
                </RenderIf>
                {columns?.map((column, colIndex) => (
                  <div className="category-container" key={column?.key || `key-${colIndex}`}>
                    <CardInfoRow data={data} infoRowKey={identity.isTruthyString(column?.key) ? column?.key : 'n/a'} />
                  </div>
                ))}
                <RenderIf condition={data?.company! && isAngelInvestor(props.userType)}>
                  <div className="category-container" key="peopleTableAngelInvestor">
                    <CardInfoRow data={data} infoRowKey="peopleTableAngelInvestor" />
                  </div>
                </RenderIf>
              </div>
              {/* temporary solution until this component is refactored */}
              <RenderIf condition={component === 'services'}>
                <div className="buttons-section">
                  <Button
                    type="primary"
                    className="secondary-delete-button md"
                    onClick={(e) => { e.stopPropagation(); showDeleteConfirm!(data, onDeleteService) }}
                    disabled
                  >
                    Delete service
                  </Button>
                  <Button
                    type="primary"
                    className="secondary-button md"
                    onClick={(e) => { e.stopPropagation(); onEditService!(data) }}
                  >
                    Edit service
                  </Button>
                </div>
              </RenderIf>
              {/* temporary solution until this component is refactored */}
              <RenderIf condition={component === 'startup-services' || component === 'angel-investor-services'}>
                <div className="buttons-section">
                  <Button
                    type="primary"
                    className="secondary-button md"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(composeServiceProviderDetailsUrl(data?.serviceProvider?.id!));
                    }}
                  >
                    View profile
                  </Button>

                  <Tooltip
                    title={communityStatus && ![43, 49].includes(data?.serviceProvider?.id) && 'Complete the onboarding to unlock all features'}
                  >
                    <Button
                      type="primary"
                      className={classnames('primary-button md', { 'requested-service-button' : showRequestedButton(data.id, unsentRequests!) })}
                      onClick={(e) => { e.stopPropagation(); onRequestService!(data); }}
                      disabled={
                        showRequestedButton(data.id, unsentRequests!)
                        || disableRequestButton(data, unsentRequests!)
                        || (communityStatus && ![43, 49].includes(data?.serviceProvider?.id))
                        || !data.active
                      }
                    >
                      {showRequestedButton(data.id, unsentRequests!) ? 'Service requested' : 'Request service'}
                    </Button>
                  </Tooltip>
                </div>
              </RenderIf>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default GridCard;
