import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { selectAdgmLicenseByAngelInvestorId, selectAngelInvestorId, selectAngelInvestorDetails } from 'redux/selectors/angelInvestors';
import { getADGMLicenseByAngelInvestorId, updateAngelInvestorById } from 'redux/actions/angelInvestors';
import { selectUserAngelInvestorId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  adgm: selectAdgmLicenseByAngelInvestorId(state),
  userAngelInvestorId: selectUserAngelInvestorId(state),
  userType: selectUserType(state),
  id: selectAngelInvestorId(state),
  info: selectAngelInvestorDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getADGMLicenseByAngelInvestorId,
    updateAngelInvestorById,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
