import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { Button } from 'antd';
import { MemberType, AngelInvestorMemberType } from 'types/memberDetails';
import { identity, isStartup } from 'utils';
import { RequestServiceDrawerProps } from 'types/services';
import { LabelValue } from 'types/componentTypes/Input';
import { InputColumns, ServiceRequestTotal } from './components';
import useRequestServiceHook from './utils/useRequestServiceHook';
import { DataColumns } from '../components';
import { getServiceRequestOverviewColumns } from './utils/constants';

import './RequestServiceDrawer.scss';

// Define the type for your context data
interface MyContextType {
  passportNumber: string;
  setPassportNumber: React.Dispatch<React.SetStateAction<string>>;
  isAgreeCondition: boolean;
  setIsAgreeCondition: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
export const MyContext = createContext<MyContextType | undefined>(undefined);

const RequestServiceDrawer = (props: RequestServiceDrawerProps) => {
  const {
    details,
    startupId,
    teamMembers,
    angelInvestorId,
    aITeamMembers,
    userType,
  } = props;
  const {
    setIsDrawerOpen,
    addNewRequest,
    getStartupTeamMembers,
    getAngelInvestorMembers,
    getAngelInvestorById,
    getWallet,
    getAIWallet,
  } = props.actions;

  const {
    state,
    setService,
    setPriceType,
    setFurnished,
    setQuantity,
    setNoOfDesk,
    setTeamMember,
    setAITeamMember,
    setPlan,
    setTotalPrice,
    setRate,
    setAccommodationType,
    setOfficeType,
    setIsApplyingForDependant,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsUserUAERes,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setRequestDocuments,
    setDisplayDocuments,
    setETAWithSignFile,
    setPassportNo,
    setEmiratesIDNo,
  } = useRequestServiceHook();

  const [displayAllColumns, setDisplayAllColumns] = useState(true)
  const columns: LabelValue[] = useMemo(
    () => getServiceRequestOverviewColumns(details, displayAllColumns),
    [details, displayAllColumns],
  );

  useEffect(() => {
    setService({ data: details });
  }, [details]);

  useEffect(() => {
    if (identity.isTruthyNumber(startupId)) {
      getStartupTeamMembers({ id: startupId });
      getWallet({ startup_id: startupId });
    }
  }, [startupId]);

  useEffect(() => {
    if (identity.isTruthyNumber(angelInvestorId)) {
      getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: true })
      getAngelInvestorMembers({ id: angelInvestorId });
      getAIWallet({ angel_investor_id: angelInvestorId });
    }
  }, [angelInvestorId]);

  const teamMembersOptions = useMemo(() => {
    const options: { label: string, value: number }[] = [{ value: 0, label: 'Enter or choose from dropdown' }];
    teamMembers.map(({ id: value, firstName, lastName }: MemberType) => options.push({ value, label: `${firstName || 'Unknown team member'} ${lastName || ''}` }));
    return options;
  }, [teamMembers]);

  const aITteamMembersOptions = useMemo(() => {
    const options: { label: string, value: number }[] = [{ value: 0, label: 'Enter or choose from dropdown' }];
    aITeamMembers.map(({ id: value, firstName, lastName }: AngelInvestorMemberType) => options.push({ value, label: `${firstName || 'Unknown team member'} ${lastName || ''}` }));
    return options;
  }, [aITeamMembers]);

  const checkVisibility = useMemo(() => [
    identity.isTruthyString(state.request.childPlaceOfBirth),
    identity.isTruthyString(state.request.isDependantUAE),
  ].some(Boolean), [state.request]);

  const noDocumentsUpload = useMemo(() => [
    identity.isTruthyNumber(state.request.teamMember?.id),
    identity.isTruthyString(state.request.plan),
    checkVisibility,
    identity.isTruthyString(state.request.relationToTM),
    identity.isTruthyString(state.request.dependantGender),
    identity.isTruthyString(state.request.dependantFullName),
    identity.isTruthyString(state.request.dependantDateOfBirth),
  ].every(Boolean), [state.request, checkVisibility]);

  const displayAddButton = useMemo(() => [
    details.categoryId !== 3,
    (details.categoryId === 3 && !state.request.isApplyingForDependant),
    state.request.displayDocuments,
  ].some(Boolean), [details, state.request]);

  useEffect(() => {
    setDisplayAllColumns(!state.request.displayDocuments!)
  }, [state.request.displayDocuments])

  const displayBackButton = useMemo(() => [
    state.request.displayDocuments,
    displayAddButton,
  ].every((Boolean)), [state.request]);

  const [isDisabled, setIsDisabled] = useState(true)
  const [passportNumber, setPassportNumber] = useState<string>('')
  const [isAgreeCondition, setIsAgreeCondition] = useState<boolean>(false)
  // Wrap the value object in useMemo to memoize it
  const contextValue = useMemo(() => ({
    passportNumber,
    setPassportNumber,
    isAgreeCondition,
    setIsAgreeCondition,
  }), [
    passportNumber,
    setPassportNumber,
    isAgreeCondition,
    setIsAgreeCondition,
  ]);

  const plan = state.request.plan && state.request.plan.charAt(0).toUpperCase() + state.request.plan.slice(1);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    const MEMBER_ID = state.request.teamMember?.id
    const PLAN = plan
    const PASSPORT = passportNumber
    const EMIRATE = '&emirate=Abu Dhabi'
    if (
      accessToken
      && MEMBER_ID
      && PASSPORT
      && EMIRATE
      && PLAN
      && state.request.totalPrice
      && state.request.visaFile
      && isAgreeCondition
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [passportNumber, state, isAgreeCondition])

  const HandleAddToRequestButton = () => {
    if (displayAddButton) {
      addNewRequest({ data: state.request })
    } else {
      setDisplayDocuments({ data: noDocumentsUpload })
    }
  }

  return (
    <MyContext.Provider value={contextValue}>
      <div className="request-service-details">
        <DataColumns columns={columns} />

        <InputColumns
          isStartupUser={isStartup(userType)}
          state={state}
          setPlan={setPlan}
          setTeamMember={setTeamMember}
          setAITeamMember={setAITeamMember}
          setPriceType={setPriceType}
          setQuantity={setQuantity}
          setNoOfDesk={setNoOfDesk}
          setTotalPrice={setTotalPrice}
          setFurnished={setFurnished}
          setAccommodationType={setAccommodationType}
          setOfficeType={setOfficeType}
          teamMembersOptions={isStartup(userType) ? teamMembersOptions : aITteamMembersOptions}
          setIsApplyingForDependant={setIsApplyingForDependant}
          setRelationToTM={setRelationToTM}
          setIsDependantUAE={setIsDependantUAE}
          setDependantGender={setDependantGender}
          setDependantFullName={setDependantFullName}
          setDependantDateOfBirth={setDependantDateOfBirth}
          setChildPlaceOfBirth={setChildPlaceOfBirth}
          setIsUserUAERes={setIsUserUAERes}
          setPassportFile={setPassportFile}
          setVisaFile={setVisaFile}
          setVisaProofFile={setVisaProofFile}
          setEmiratesIDFile={setEmiratesIDFile}
          setRequestDocuments={setRequestDocuments}
          setETAWithSignFile={setETAWithSignFile}
          setEmiratesIDNo={setEmiratesIDNo}
          setPassportNo={setPassportNo}
        />

        <ServiceRequestTotal
          isStartupUser={isStartup(userType)}
          memberId={state.request.teamMember?.id}
          plan={plan}
          quantity={state.request.quantity}
          noOfDesk={state.request.noOfDesk}
          officeType={state.request.officeType}
          passport={passportNumber}
          price={state.service.servicePrice?.toPrice}
          setTotalPrice={setTotalPrice}
          setRate={setRate}
          setPlan={setPlan}
        />

        <div className="submit__wrap">
          <Button
            className="secondary-button"
            onClick={() => (displayBackButton
              ? setDisplayDocuments({ data: false })
              : setIsDrawerOpen({ isDrawerOpen: false }))}
          >
            {displayBackButton ? 'go back' : 'discard'}
          </Button>

          <Button
            disabled={details.categoryId === 3 ? isDisabled : state.disabled}
            onClick={HandleAddToRequestButton}
            className="primary-button"
          >
            {displayAddButton ? 'Add to requests' : 'Go to documents'}
          </Button>
        </div>
      </div>
    </MyContext.Provider>
  )
}

export default RequestServiceDrawer;
