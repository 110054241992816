import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AngelInvestorOverviewProps } from 'types/angelInvestors/AngelInvestorOverview';
import {
  getOffset,
  useTableHook,
  identity, isPartner, DRAWERS, isEitherAdmin, getRowKey,
} from 'utils'
import { StartupType } from '../../types/startups'
import { composeStartupDetailsUrl, composeAngelInvestorDetailsUrl } from '../../routes/routeComposers'
import { DataPayload } from '../../types/reduxTypes/ActionTypes'
import {
  Table,
  RenderIf,
  gridPaginationOptions,
  paginationOptions,
  GridTable,
  FiltersWithIcons,
} from '../components';
import { startupsColumns, angelInvestorsColumns } from '../utils/constants/columns'
import { FILTERS_MAP } from '../components/SearchBar/utils/constants'

const AngelInvestorsOverviewView = (props: AngelInvestorOverviewProps) => {
  const {
    angelInvestors,
    sectorOptions,
    isLoading,
    isRowTableLayout,
    userType,
    filters,
    filtersActive,
    angelInvestorCount,
  } = props;

  const {
    getAngelInvestors,
    getAngelInvestorSectors,
    getAngelInvestorCount,
    setTableLayout,
    setDrawerBody,
    setIsDrawerOpen,
    setAngelInvestorFilters,
    setAngelInvestorId,
  } = props.actions;

  const { state, setLimit, setCurrentPage } = useTableHook();

  const { limit, currentPage, openFilters } = state;

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && identity.isEmptyArray(sectorOptions)) {
      getAngelInvestorSectors();
    }
  }, [isLoading]);

  useEffect(() => {
    let limitDefault;
    if (isRowTableLayout && !isPartner(userType)) {
      limitDefault = gridPaginationOptions.includes(limit) ? 10 : limit;
    } else {
      limitDefault = paginationOptions.includes(limit) ? 12 : limit;
    }
    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    if (
      (isRowTableLayout && !isPartner(userType) && paginationOptions.includes(limit))
      || (gridPaginationOptions.includes(limit))
    ) {
      getAngelInvestors({
        isPreload: true,
        limit,
        offset: getOffset(limit, currentPage),
        filters,
      });

      getAngelInvestorCount({ filters });
    }
  }, [limit, currentPage, filters, isRowTableLayout]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    setTableLayout({ isRowTableLayout: false })
  }, []);

  const openAngelInvestorDetails = (record: StartupType) => history.push(composeAngelInvestorDetailsUrl(record.id));

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addAngelInvestor });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const setSector = (payload: DataPayload<number | 'all'>) => {
    let sector: number[] | undefined = identity.isFullArray(filters.sector) ? [...filters.sector] : [];

    if (payload.data !== 'all') {
      const hasId = sector!.includes(payload.data as number);

      if (hasId) {
        sector = sector!.filter((item) => item !== payload.data as number);
      } else {
        sector!.push(payload.data as number);
      }
    } else {
      sector = undefined;
    }

    setAngelInvestorFilters({ ...filters, sector });
    setCurrentPage(1);
  }

  const openGetInTouchDrawer = (record: StartupType) => {
    setDrawerBody({ drawerBody: DRAWERS.getInTouchDrawer });
    setIsDrawerOpen({ isDrawerOpen: true });
    setAngelInvestorId({ data: record.id });
  }

  const setSearchString = (searchString: string) => {
    setAngelInvestorFilters({ ...filters, searchString });
    setCurrentPage(1);
  }

  return (
    <div className="content-container angel-investors-overview">
      <RenderIf condition={!isPartner(userType)}>
        <Table
          searchString={filters.searchString}
          isLoading={isLoading}
          onRow={(record) => ({ onClick: () => openAngelInvestorDetails(record) })}
          onGridCardClick={openAngelInvestorDetails}
          showSearchBar
          showSearchField
          leftContent={isEitherAdmin(userType) ? 'add-button' : undefined}
          addButtonText="angel investor"
          placeholder="angelinvestor"
          dataSource={angelInvestors}
          columns={angelInvestorsColumns}
          showCustomPagination
          limit={limit}
          sectorOptions={sectorOptions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={angelInvestorCount}
          showDisplayButtons
          tableLocation="angel-investor"
          onAddButtonClick={onAddButtonClick}
          rowKey={getRowKey(angelInvestors, 'angelinvestor', 'id')}
          activeFilters={filters.sector}
          setFilter={setSector}
          filtersActive={filtersActive}
          setSearchString={setSearchString}
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.angelInvestors}
          component="angel-investor"
        />
      </RenderIf>

      <RenderIf condition={isPartner(userType)}>
        <FiltersWithIcons
          categories={[]}
          icons={[]}
          displayFilters
          filters={filters}
          open={openFilters}
          setFilter={setSector}
          setFilters={setAngelInvestorFilters}
          activeFilters={filters.sector}
          filterType="startup"
        />

        <GridTable
          isLoading={isLoading}
          dataSource={angelInvestors}
          columns={startupsColumns}
          rowKey={getRowKey(angelInvestors, 'startup', 'id')}
          onGridCardClick={() => null}
          firstButtonTitle="View profile"
          secondButtonTitle="Get in touch"
          firstButtonAction={(record: StartupType) => history.push(composeStartupDetailsUrl(record?.id!))}
          secondButtonAction={(record: StartupType) => openGetInTouchDrawer(record)}
          hasDeleteOption={false}
          showCustomPagination
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={angelInvestorCount}
          component="angel-investor"
        />
      </RenderIf>
    </div>
  )
}

export default AngelInvestorsOverviewView;
