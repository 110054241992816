import React, { useEffect } from 'react';
import { Button, Form, Spin } from 'antd';

import ConfirmPassword from 'components/components/ConfirmPassword';
import PasswordField from 'components/components/ConfirmPassword/components/PasswordField/View';
import { requiredValidation, validatePasswordStrength } from 'utils';
import { ChangePasswordPayload, ChangePasswordProps } from 'types/auth/changePassword';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';

import './ChangePassword.scss';

const ChangePassword = (props: ChangePasswordProps) => {
  const { isLoading, resetForm, actions: { changePassword } } = props;
  const [form] = Form.useForm();

  const onFinish = (formData: ChangePasswordPayload) => {
    changePassword(formData);
  };

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
    }
  }, [resetForm]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="change-password-page">
          <Form
            className="change-password-container"
            form={form}
            onFinish={onFinish}
            validateTrigger="onChange"
            onError={scrollToFirstError}
          >
            <div className="sub-form">
              <p className="heading">Change Password</p>
              <PasswordField
                label="Old Password"
                name="oldPassword"
                rules={[requiredValidation, validatePasswordStrength]}
              />
              <ConfirmPassword />
              <Form.Item className="change-password-button">
                <Button htmlType="submit" className="change-password-button">Change password</Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default ChangePassword;
