import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectEmailTemplates, selectIncentiveTemplates } from 'redux/selectors/settingsOverview';
import {
  createAngelInvestor,
} from '../../redux/actions/angelInvestors';
import { getEmailTemplates, getIncentiveTemplates } from '../../redux/actions/settingsOverview';
import { StateType } from '../../types'

const mapStateToProps = (state: StateType) => ({
  emailTemplates: selectEmailTemplates(state),
  incentiveTemplates: selectIncentiveTemplates(state),
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createAngelInvestor,
    getEmailTemplates,
    getIncentiveTemplates,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
