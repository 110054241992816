export const AUTH_API_PREFIX = '/api/d/auth';

export const API_PREFIX = '/api';

export const CHANGE_PASSWORD_API = 'changepassword';

export const LOGIN_API = 'login';

export const LOGOUT_API = 'logout';

export const RESET_PASSWORD_API = 'resetpassword';
