import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { AngelInvestorFiltersType } from 'types/angelInvestors';
import {
  resetFilters as resetFiltersAC,
  setFilters as setFiltersAC,
  setAcceptanceLetter as setAcceptanceLetterAC,
  setIncentiveAgreement as setIncentiveAgreementAC,
  setSector as setSectorAC,
} from './actions';
import { reducer, initialState } from './reducer';

export const useAngelInvestorsFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFilters = (payload: AngelInvestorFiltersType) => {
    dispatch(setFiltersAC(payload));
  }

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  const setAcceptanceLetter = (payload: DataPayload<number>) => {
    dispatch(setAcceptanceLetterAC(payload));
  }

  const setIncentiveAgreement = (payload: DataPayload<number>) => {
    dispatch(setIncentiveAgreementAC(payload));
  }

  const setSector = (payload: DataPayload<number[]>) => {
    dispatch(setSectorAC(payload));
  }

  return {
    state,
    initialState,
    resetFilters,
    setFilters,
    setAcceptanceLetter,
    setIncentiveAgreement,
    setSector,
  };
}

export default useAngelInvestorsFiltersHook;
