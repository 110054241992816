/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from 'axios';

import { clearUserSession, getRefreshedTokens } from 'packages/local_storage';
import identity from 'utils/identity';
import {
  ApiResponseType, ErrorResponseType, HttpRequestConfig, ResponseType,
} from '../types';
import {
  ANONYMOUS_ENDPOINTS,
  AUTHORIZATION_HEADER,
  X_CSRF_TOKEN_HEADER,
} from './constants';
import { convertApiError, convertAxiosError } from './converters';
import { updateContentRequestHeader } from './httpMethodHelper';
import {
  AUTH_API_PREFIX, API_PREFIX, LOGIN_API, RESET_PASSWORD_API,
} from '../../authentication_repository'

const EXCLUDED_ROUTES = [
  `${AUTH_API_PREFIX}/${LOGIN_API}`,
  `${API_PREFIX}/${RESET_PASSWORD_API}`,
];

export const authInterceptor = (request: HttpRequestConfig) => {
  const isAnonymous = identity.isTruthyString(request.url) && ANONYMOUS_ENDPOINTS.includes(request.url!);

  if (isAnonymous) {
    return request;
  }

  const { accessToken, sessionId } = getRefreshedTokens();
  if (identity.isObjWithChildren(request.headers)) {
    if (!identity.isFalsy(accessToken)) {
      request.headers![AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
    }

    if (!identity.isFalsy(sessionId)) {
      request.headers![X_CSRF_TOKEN_HEADER] = sessionId!;
    }

    request.headers = updateContentRequestHeader(request);
  }

  return request;
};

export const responseInterceptor = (response: AxiosResponse): ApiResponseType<ResponseType> => ({
  data: response.data,
  httpStatus: response.status,
});

export const errorInterceptor = (axiosError: AxiosError<any>): ApiResponseType<ErrorResponseType> => {
  // TODO: Add check to clear local storage when 401 received (confirm with BE)

  let error;

  if (identity.isObjWithChildren(axiosError.response?.data)) {
    error = convertApiError(axiosError.response?.data);
  } else {
    error = convertAxiosError(axiosError);
  }

  let httpStatus: number;

  if (identity.isTruthyNumber(axiosError.response?.status)) {
    httpStatus = axiosError.response?.status!;
  } else {
    httpStatus = 503; // indicates connection timeout
  }

  if (httpStatus === 401) {
    const requestUrl = axiosError.config?.url || '';
    const isExcludedRoute = EXCLUDED_ROUTES.some((route) => requestUrl.includes(route));

    if (!isExcludedRoute) {
      clearUserSession();
    }
  }

  return { error, httpStatus };
};
