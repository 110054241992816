import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { AngelInvestorFiltersType } from 'types/angelInvestors';
import { types } from './actions';

const initialState: AngelInvestorFiltersType = {
  capitalRaised: '',
  fieldSelection: [''],
  incentiveAmount: '',
  locations: [''],
  searchString: '',
  stage: 0,
  acceptanceLetter: 0,
  incentiveAgreement: 0,
  sector: [],
}

const handleSetAcceptanceLetter = (state: AngelInvestorFiltersType, payload: DataPayload<number>) => ({
  ...state,
  acceptanceLetter: payload.data,
})

const handleSetIncentiveAgreement = (state: AngelInvestorFiltersType, payload: DataPayload<number>) => ({
  ...state,
  incentiveAgreement: payload.data,
})

const handleSetSector = (state: AngelInvestorFiltersType, payload: DataPayload<number[]>) => ({
  ...state,
  sector: payload.data,
});

const handleSetFilters = (state: AngelInvestorFiltersType, payload: AngelInvestorFiltersType) => payload;

const reducer = (
  state: AngelInvestorFiltersType,
  action: PayloadAction<DataPayload<number[] | number | string> | AngelInvestorFiltersType | object>,
) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ACCEPTANCE_LETTER: {
      return handleSetAcceptanceLetter(state, <DataPayload<number>>payload);
    }
    case types.SET_INCENTIVE_AGREEMENT: {
      return handleSetIncentiveAgreement(state, <DataPayload<number>>payload);
    }
    case types.SET_SECTOR: {
      return handleSetSector(state, <DataPayload<number[]>>payload);
    }
    case types.SET_FILTERS: {
      return handleSetFilters(state, <AngelInvestorFiltersType>payload);
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
