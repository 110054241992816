import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit';

import config from 'assets/config';
import { createServiceProviderMember } from 'packages/people_repository';
import {
  createServiceProvider,
  getServiceProvider,
  getServiceProvidersCount,
  getServiceProvidersList,
  editServiceProvider,
} from 'packages/service_repository';
import showNotification from 'services/utils/showNotification';
import { AddServiceProviderPayload, GetServiceProviderPayload, PatchServiceProviderPayload } from 'types/reduxTypes/ActionTypes/ServiceProviderTypes';
import { identity, isAngelInvestor } from 'utils'
import { GetAllQueryPayload } from 'types/reduxTypes/ActionTypes';
import { ParamsPayload } from 'packages/http_client';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from '../../actions/workspace';
import {
  setServiceProviders,
  types,
  setServiceProviderById,
  setServiceProviderLoading,
  setServiceProvidersCount,
  getServiceProviders,
} from '../../actions/serviceProviders';
import { setIsLoading } from '../../actions/services';
import { selectUserType } from '../../selectors/auth';
import { UserRoleType } from '../../../types/auth'

function * handleGetServiceProviders(action: PayloadAction<GetAllQueryPayload<ParamsPayload>>) {
  const { query } = action.payload;
  const userType: UserRoleType = yield select(selectUserType);

  const params = {
    ...query,
    ...(isAngelInvestor(userType) && { isAvailableForAiCommunity: 1 }),
  }

  yield all([
    put(setServiceProviderLoading({ isLoading: true })),
    put(setIsLoading({ isLoading: true })),
  ]);

  const { data, error, httpStatus } = yield call(getServiceProvidersList, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServiceProviders({ data }));
  }

  yield all([
    put(setIsLoading({ isLoading: false })),
    put(setServiceProviderLoading({ isLoading: false })),
  ]);
}

function * handleGetServiceProvidersCount(action: PayloadAction<GetAllQueryPayload<ParamsPayload>>) {
  const { query } = action.payload;
  const userType: UserRoleType = yield select(selectUserType);

  const params = {
    ...query,
    ...(isAngelInvestor(userType) && { isAvailableForAiCommunity: 1 }),
  }

  const { data, error, httpStatus } = yield call(getServiceProvidersCount, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers count: ${error.message}`, true, httpStatus);
  } else {
    const [{ id__count }] = data;
    yield put(setServiceProvidersCount({ data: id__count }));
  }
}

function * handleGetServiceProvider(action: PayloadAction<GetServiceProviderPayload>) {
  const { id, component } = action.payload;

  if (component !== 'startup') {
    yield put(setServiceProviderLoading({ isLoading: true }));

    const { data, error: apiError, httpStatus } = yield call(getServiceProvider, id);
    if (identity.isObjWithChildren(apiError)) {
      showNotification(`Error fetching service provider: ${apiError.message}`, true, httpStatus);
    } else {
      yield put(setServiceProviderById({ serviceProvider: data }));
    }

    yield put(setServiceProviderLoading({ isLoading: false }));
  }
}

function * handleAddServiceProvider(action: PayloadAction<AddServiceProviderPayload>) {
  const { data } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data: serviceProviderId, error, httpStatus } = yield call(createServiceProvider, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error creating service provider: ${error.message}`, true, httpStatus);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    // Add Service Provider Member to receive activation link
    const { recipientSameAsContactPerson, recipients } = data;

    if (identity.isFalsy(recipientSameAsContactPerson)
      && identity.isTruthyNumber(serviceProviderId)
      && identity.isFullArray(recipients)) {
      const { error: memberError, httpStatus: memberHttpStatus } = yield call(
        createServiceProviderMember,
        serviceProviderId,
        recipients![0], // only limiting recipient to 1 person
      );

      if (identity.isObjWithChildren(memberError)) {
        showNotification(`Error adding recipient(s): ${error.message}`, true, memberHttpStatus);
      }
    }
    const params: ParamsPayload = {
      limit: config.TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
    };
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    showNotification('Service provider successfully added.');

    yield put(getServiceProviders({ query: params }));
    yield put(setIsDrawerLoading({ isLoading: false }));
  }
}

function * handlePatchServiceProviderById(action: PayloadAction<PatchServiceProviderPayload>) {
  const { id, data } = action.payload;
  yield put(setServiceProviderLoading({ isLoading: true }));
  const { error, httpStatus } = yield call(editServiceProvider, id, data);
  if (error) {
    showNotification(`Error updating service: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Service provider updated successfully!');
  }
  yield put(setServiceProviderLoading({ isLoading: false }));
}

export default function * serviceProvidersSagas() {
  yield all([
    takeLatest(types.GET_SERVICE_PROVIDERS, handleGetServiceProviders),
    takeLatest(types.GET_SERVICE_PROVIDER_BY_ID, handleGetServiceProvider),
    takeLatest(types.ADD_SERVICE_PROVIDER, handleAddServiceProvider),
    takeLatest(types.PATCH_SERVICE_PROVIDER_DATA_BY_ID, handlePatchServiceProviderById),
    takeLatest(types.GET_SERVICE_PROVIDERS_COUNT, handleGetServiceProvidersCount),
  ]);
}
