import React from 'react';

import SVGWrap from './SVGWrap';

const AngelInvestorsIcon = () => (
  <SVGWrap size={64} fill="none" stroke={2}>
    <g id="Layer_17" data-name="Layer 17">
      <path
        d="M14.84 43.11a7.17 7.17 0 0 0 1.53-.44l1.48-11.1A11.42 11.42 0 0 1 22 24.22l-3.28-3A1 1 0 0 0 18 21s-4.63 0-7.29-2.71A75.07 75.07 0 0 1 4.8 11.4a1 1 0 0 0-1.8.71 33.64 33.64 0 0 0 2.86 10.56l-1.46-.59A1 1 0 0 0 3 23a9.43 9.43 0 0 0 .2 1.73 11.19 11.19 0 0 0 4 6.87l-2.2.15a1 1 0 0 0-.82 1.44 12.31 12.31 0 0 0 3.52 4.07 8.5 8.5 0 0 0 2.3 1.31l-2.3 2.17a1 1 0 0 0 .3 1.63 12.86 12.86 0 0 0 6.84.74zm44.79-21.04-1.46.59A33.64 33.64 0 0 0 61 12.11a1 1 0 0 0-1.79-.71 75.07 75.07 0 0 1-5.91 6.89C50.63 21 46 21 46 21a1 1 0 0 0-.67.26l-3.28 3a11.44 11.44 0 0 1 4.1 7.35l1.49 11.1a6.93 6.93 0 0 0 1.52.44 12.86 12.86 0 0 0 6.84-.78 1 1 0 0 0 .28-1.63L54 38.57a8.5 8.5 0 0 0 2.24-1.31 12.31 12.31 0 0 0 3.52-4.07 1 1 0 0 0-.76-1.44l-2.15-.15a11.19 11.19 0 0 0 4-6.87A9.43 9.43 0 0 0 61 23a1 1 0 0 0-1.37-.93zM38 15.92l-2.65.66a2.85 2.85 0 0 1-1.79-.28 4.11 4.11 0 0 0-2.56-.07c-.87.22-1.39.58-2.21.37L26 15.92a1 1 0 0 0-1 1.56 15.22 15.22 0 0 1 2.21 3.82 2.78 2.78 0 0 1 1.29-.3h7.06a3 3 0 0 1 1.34.32l.26.13a32.13 32.13 0 0 1 2-4.08A1 1 0 0 0 38 15.92zM31 34h-.29a2 2 0 0 0 0 4H31zm4.29 8a2 2 0 0 0-2-2H33v4h.29a2 2 0 0 0 2-2z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M17.43 49.84A4.55 4.55 0 0 0 21.94 55h20.12a4.55 4.55 0 0 0 4.51-5.16l-2.4-18A9.44 9.44 0 0 0 39 24.63l-3-1.52a1 1 0 0 0-.45-.11h-7.08c-.42 0-.5.17-3.5 1.63a9.44 9.44 0 0 0-5.14 7.2zM30.71 40a4 4 0 0 1 0-8H31v-2.43h2V32h.29a4 4 0 0 1 4 4h-2a2 2 0 0 0-2-2H33v4h.29a4 4 0 0 1 0 8H33v2.43h-2V46h-.29a4 4 0 0 1-4-4h2a2 2 0 0 0 2 2H31v-4zM42 9.5C42 6.74 35.71 6 32 6s-10 .74-10 3.5S28.29 13 32 13s10-.74 10-3.5zM32 11c-4.75 0-7.4-1-7.94-1.5C24.6 9 27.25 8 32 8s7.4 1 7.94 1.5C39.4 10 36.75 11 32 11z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </SVGWrap>
);

export default AngelInvestorsIcon;
