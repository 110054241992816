import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  getAngelInvestorById, getAngelInvestorMembers, getAngelInvestorServiceProviders, updateAngelInvestorById,
} from 'redux/actions/angelInvestors';
import { StateType } from 'types';
import {
  selectAngelInvestorName,
  selectAboutAngelInvestor,
  selectStatCards,
  selectAngelInvestorTeamMembers,
  selectAngelInvestorDetails,
  selectAngelInvestorDetailsRequests,
  selectIsLoading,
  selectPartnersStatCards,
  selectAngelInvestorServiceProviders,
} from 'redux/selectors/angelInvestors';
import { getRequestsByOwnerId, getServiceRequestByOwnerId } from 'redux/actions/request';
import { selectAngelInvestorServiceRequests } from 'redux/selectors/request';
import { selectUserAngelInvestorId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  angelInvestorName: selectAngelInvestorName(state),
  aboutAngelInvestor: selectAboutAngelInvestor(state),
  statCards: selectStatCards(state),
  partnersStatCards: selectPartnersStatCards(state),
  teamMembers: selectAngelInvestorTeamMembers(state),
  info: selectAngelInvestorDetails(state),
  requests: selectAngelInvestorDetailsRequests(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  userAngelInvestorId: selectUserAngelInvestorId(state),
  angelInvestorServiceProviders: selectAngelInvestorServiceProviders(state),
  serviceRequests: selectAngelInvestorServiceRequests(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestorById,
    getAngelInvestorMembers,
    getRequestsByOwnerId,
    getAngelInvestorServiceProviders,
    getServiceRequestByOwnerId,
    updateAngelInvestorById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
