import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import {
  selectIsLoading,
  selectAngelInvestorCount,
  selectAngelInvestorFilters,
  selectAngelInvestors,
  selectAngelInvestorFiltersActive,
  selectSectorOptions,
} from 'redux/selectors/angelInvestors'
import { selectUserType } from 'redux/selectors/auth';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';
import { setIsDrawerOpen, setDrawerBody, setTableLayout } from 'redux/actions/workspace';
import {
  getAngelInvestors,
  getAngelInvestorCount,
  getAngelInvestorSectors,
  setAngelInvestorFilters,
  setAngelInvestorId,
} from 'redux/actions/angelInvestors';

const mapStateToProps = (state: StateType) => ({
  angelInvestors: selectAngelInvestors(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  angelInvestorCount: selectAngelInvestorCount(state),
  sectorOptions: selectSectorOptions(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  filters: selectAngelInvestorFilters(state),
  filtersActive: selectAngelInvestorFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestors,
    getAngelInvestorCount,
    getAngelInvestorSectors,
    setIsDrawerOpen,
    setDrawerBody,
    setAngelInvestorFilters,
    setTableLayout,
    setAngelInvestorId,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
