import React, { useState } from 'react';
import { RcFile } from 'antd/lib/upload';

import EventEmitter from 'event_emitter';
import { ServiceCategoryType, ServiceSubCategoryType } from 'types/services';
import { ServiceCategoryFormProps } from 'types/services/addService';
import { identity } from 'utils';
import { Input, RenderIf } from '../../../components';
import Row from '../../../components/FormBuilder/components/Row';
import AddButtonDrawer from '../../../components/AddButtonDrawer';
import { resetFiltersEvent, subCategoryColumns } from '../../utils';

const ServiceCategoryForm = (props: ServiceCategoryFormProps) => {
  const {
    categories,
    subCategories,
    form,
    setOfferDocLink,
    actions: { getServiceSubCategories },
  } = props;

  const [serviceCategory, setServiceCategory] = useState<number>(0);
  const [isAvailableForAI, setIsAvailableForAI] = useState<boolean>(false);

  const handleServiceCategoryChange = (categoryId: number) => {
    if (categoryId !== serviceCategory && identity.isTruthyNumber(categoryId)) {
      getServiceSubCategories({ id: categoryId });
      form.setFieldValue('subCategories', []);
      EventEmitter.emit(resetFiltersEvent);
    }
    setServiceCategory(categoryId);
  };

  const handleIsAvailableForAICommunity = (data: boolean) => {
    setIsAvailableForAI(data);
  };

  const beforeUpload = (file: RcFile) => {
    setOfferDocLink!(file as Blob);
    return false;
  }

  return (
    <div>
      <Row>
        <Input
          name="category"
          label="Category"
          layout="vertical"
          required
        >
          <Input.Select
            placeholder="Select Category"
            options={categories?.map((category: ServiceCategoryType) => (
              { value: category.id!, label: category.name! }
            ))}
            value={serviceCategory}
            onChange={(value: number) => handleServiceCategoryChange(value)}
          />
        </Input>
      </Row>

      <RenderIf condition={identity.isTruthyNumber(serviceCategory) && identity.isFullArray(subCategories)}>
        <Row oneColumn>
          <AddButtonDrawer
            form={form}
            drawerTitle="Sub Categories"
            columns={subCategoryColumns}
            dataSource={subCategories!}
            options={subCategories?.map((category: ServiceSubCategoryType) => (
              { value: category.id!, label: category.name! }
            ))!}
            filterBy="name"
            placeholder="sub categories"
            name="subCategories"
            rowKeyName="id"
            label="Sub Categories"
            buttonText="Sub Categories"
            eventName={resetFiltersEvent}
          />
        </Row>
      </RenderIf>

      <Row oneColumn>
        <Input
          label="Available For Angel Investors"
          layout="vertical"
          name="isAvailableForAICommunity"
        >
          <Input.Switch
            onChange={handleIsAvailableForAICommunity}
            checked={isAvailableForAI}
          />
        </Input>
      </Row>

      <Row>
        <Input
          label="Offer Document"
          layout="vertical"
          name="offerDocLink"
        >
          <Input.DraggerUpload
            accept=".pdf"
            name="offerDocLink"
            beforeUpload={beforeUpload}
            isRoundableButton
          />
        </Input>
      </Row>
    </div>
  );
};

export default ServiceCategoryForm;
