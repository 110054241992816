import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { selectAdgmLicenseByStartupId, selectStartupId, selectStartupDetails } from 'redux/selectors/startups';
import { getADGMLicenseByStartupId, updateStartupById } from 'redux/actions/startups';
import { selectUserStartupId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  adgm: selectAdgmLicenseByStartupId(state),
  userStartupId: selectUserStartupId(state),
  userType: selectUserType(state),
  id: selectStartupId(state),
  info: selectStartupDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getADGMLicenseByStartupId,
    updateStartupById,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
