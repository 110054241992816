import React, { useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import classnames from 'classnames';

import { RenderIf, TitleWithActions } from 'components/components';
import { ServicesAndServiceProvidersProps } from 'types/services';
import {
  isEitherAdmin,
  isServiceProvider,
  isStartup,
  isAngelInvestor,
  useTableHook,
  identity,
} from 'utils';
import { useServicesOverviewHook } from './utils';
import {
  ServiceProviders,
  Services,
  SubTabs,
  Tabs,
} from './components';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';

import './ServicesAndServiceProviders.scss';

const ServicesAndServiceProviders = (props: ServicesAndServiceProvidersProps) => {
  const {
    userType,
    activeTab,
    isLoading,
    filters,
    categories,
    subCategories,
  } = props

  const {
    getServiceProvidersCount,
    getServicesCount,
    getServiceCategories,
    getAllServiceSubCategories,
  } = props.actions

  const activeTabIsServices = useMemo(() => activeTab === 'services', [activeTab]);

  const {
    state: servicesState,
    setServiceProviderFilter,
    setActiveSpFilters,
    setServiceProvidersFiltersChanged,
    setServiceProviderName,
    setSpCurrentPage,
    setSpLimit,
  } = useServicesOverviewHook();

  const {
    state,
    setLimit,
    setCurrentPage,
    setOpenFilters,
    setFiltersChanged,
  } = useTableHook();

  const {
    serviceProviderName,
    activeSpFilters,
    spCurrentPage,
    spLimit,
    serviceProviderFilter,
    serviceProvidersFiltersChanged,
  } = servicesState;

  const {
    openFilters,
    filtersChanged,
    limit,
    currentPage,
  } = state;

  useEffect(() => {
    getServicesCount({ filters })

    if (
      isEitherAdmin(userType)
      || isStartup(userType)
      || isAngelInvestor(userType)
    ) {
      getServiceProvidersCount({ query: { categories: serviceProviderFilter } });

      if (identity.isEmptyArray(subCategories)) {
        getServiceCategories();
      }
      if (identity.isEmptyArray(categories)) {
        getAllServiceSubCategories();
      }
    }
  }, [userType]);

  return (
    <div className={classnames('content-container', {
      'services-overview': activeTabIsServices,
      'service-providers-overview': !activeTabIsServices,
    })}
    >
      <RenderIf condition={isServiceProvider(userType)}>
        <TitleWithActions
          size="l"
          title="Services"
          subtitle="View, manage, or add your services for startups to choose from."
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.services}
        />
      </RenderIf>

      <Tabs setCurrentPage={setCurrentPage} setSpCurrentPage={setSpCurrentPage} />

      <SubTabs
        activeSpFilters={activeSpFilters}
        serviceProviderName={serviceProviderName}
        setOpenFilters={setOpenFilters}
        openFilters={openFilters}
        setFiltersChanged={setFiltersChanged}
        setServiceProviderName={setServiceProviderName}
        setSpCurrentPage={setSpCurrentPage}
        setActiveSpFilters={setActiveSpFilters}
        setServiceProviderFilter={setServiceProviderFilter}
        setServiceProvidersFiltersChanged={setServiceProvidersFiltersChanged}
      />

      <Spin spinning={isLoading}>
        <RenderIf condition={!activeTabIsServices}>
          <ServiceProviders
            serviceProvidersFiltersChanged={serviceProvidersFiltersChanged}
            setServiceProvidersFiltersChanged={setServiceProvidersFiltersChanged}
            setServiceProviderFilter={setServiceProviderFilter}
            setActiveSpFilters={setActiveSpFilters}
            setServiceProviderName={setServiceProviderName}
            setSpCurrentPage={setSpCurrentPage}
            setSpLimit={setSpLimit}
            setFiltersChanged={setFiltersChanged}
            activeSpFilters={activeSpFilters}
            limit={spLimit}
            spCurrentPage={spCurrentPage}
          />
        </RenderIf>

        <RenderIf condition={activeTabIsServices || isServiceProvider(userType)}>
          <Services
            setCurrentPage={setCurrentPage}
            setFiltersChanged={setFiltersChanged}
            limit={limit}
            setLimit={setLimit}
            currentPage={currentPage}
            filtersChanged={filtersChanged}
          />
        </RenderIf>
      </Spin>
    </div>
  );
}

export default ServicesAndServiceProviders;
