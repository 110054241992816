import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Spin } from 'antd';
import routes from 'routes';
import { composeEditStartupUrl } from 'routes/routeComposers';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  StartupType,
  StartupStatusType,
} from 'packages/startup_repository';
import {
  About,
  Breadcrumb,
  RenderIf,
  Requests,
  StartupWallet,
  StatCards,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
} from 'components/components';
import {
  identity,
  getRowKey,
  isPartner,
  getImageLink,
  isEitherAdmin,
  isStartupFounder,
  isExpired,
} from 'utils';
import { RequestType } from 'types/requests';
import { StartupDetailsProps } from 'types/startups/startupDetails';
import { getServiceRequestByStartupId } from 'packages/service_request_repository/api';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { CohortDetails } from './components';
import { serviceProvidersColumns, startupTeamMembersColumns } from '../utils/constants/columns';

import './StartupDetails.scss';

const StartupDetails = (props: StartupDetailsProps) => {
  const {
    aboutStartup,
    info,
    statCards,
    teamMembers,
    userType,
    isLoading,
    partnersStatCards,
    userStartupId,
    startupServiceProviders,
    startups,
  } = props;

  const {
    getStartupById,
    getStartupTeamMembers,
    getRequestsByOwnerId,
    getStartupServiceProviders,
    updateStartupById,
  } = props.actions

  const params: { id?: string } = useParams();
  const { id } = params;

  const [startupStatus, setStartupStatus] = useState<StartupStatusType>();
  const [startupRequests, setStartupRequests] = useState<RequestType[]>();
  const [startupDetails, setStartupDetails] = useState<StartupType>();

  const {
    primarySector,
    secondarySector,
    founderEmail,
    stageName,
    numberOfEmployees,
    id: sId,
    name,
    imageLink,
    location,
    wallet,
    status,
  } = info;

  const rightContent = [
    {
      label: 'industry',
      value: identity.isTruthyString(primarySector?.name) ? primarySector?.name : secondarySector?.name,
    },
    {
      label: 'Founder Email',
      value: identity.isTruthyString(founderEmail) ? founderEmail : NOT_SPECIFIED,
    },
    { label: 'stage', value: stageName },
    { label: 'employees', value: numberOfEmployees },
  ];

  const socialMediaLinks = {
    facebook: info.facebook,
    instagram: info.instagram,
    twitter: info.twitter,
    linkedIn: info.linkedIn,
  };

  const startupId = identity.isTruthyNumber(userStartupId) ? userStartupId : parseInt(id!, 10);
  const currentStartup = startups?.[0];

  const handleSetAngelInvestor = async (newStatus: number) => {
    updateStartupById({
      id,
      startup: {
        statusId: newStatus,
      },
    })
  }

  useEffect(() => {
    getStartupById({ id: startupId, owner: 'startup', isUpdate: true });
    getStartupTeamMembers({ id: startupId });
    getRequestsByOwnerId({ id: startupId, owner: 'startup' });
    getStartupServiceProviders({ query: { startupId, isPreload: true } });
  }, [startupId]);

  useEffect(() => {
    if (currentStartup?.id) {
      setStartupStatus(currentStartup?.status);
      setStartupDetails(currentStartup);
    }
  }, [currentStartup?.id])

  useEffect(() => {
    const fetchStartupRequests = async () => {
      try {
        const result = await getServiceRequestByStartupId(startupId, true);

        const sortedRequests = result?.data?.sort(
          (a: any, b: any) => Date.parse(b.applicationDate) - Date.parse(a.applicationDate),
        );

        setStartupRequests(sortedRequests.slice(0, 5));
      } catch { /* not */ }
    };

    if (startupId) {
      fetchStartupRequests().then();
    }
  }, [startupId]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="startup-details">
          <RenderIf condition={startupId !== userStartupId}>
            <Breadcrumb navigateTo={routes.startupsUrl} routeName="startups" />
          </RenderIf>
          <RenderIf condition={isExpired(startupStatus?.code!)}>
            <StaticNotification
              message="The incentives period for this startup has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>
          <TitleWithActions
            title="Startup overview"
            buttonTitle="profile"
            link={startupId === userStartupId ? routes.editStartupProfileUrl : composeEditStartupUrl(sId)}
            isEditButton
            showButton={isEitherAdmin(userType) || (isStartupFounder(userType) && startupId === userStartupId)}
          >
            <RenderIf
              condition={
                isEitherAdmin(userType)
                && (status.id <= 7 || [11, 51].includes(status.id))
              }
            >
              <Button
                className="secondary-button md"
                onClick={() => handleSetAngelInvestor(status.id === 7 ? 9 : 8)}
              >
                {status.id === 7 ? 'Terminate' : 'Drop Out'}
              </Button>
            </RenderIf>
          </TitleWithActions>
          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
            userType={userType}
          />
          <RenderIf condition={identity.isObjWithChildren(wallet) && isEitherAdmin(userType)}>
            <StartupWallet
              wallet={wallet}
              includeGraph
              showInfo
              showTransactionButton
              startupId={startupId}
            />
          </RenderIf>

          <RenderIf condition={!isPartner(userType)}>
            <About
              title={`About ${name}`}
              isStartup={!isPartner(userType)}
              description={aboutStartup}
              startupDetails={startupDetails}
              status={status.id}
            />
          </RenderIf>

          <RenderIf condition={isPartner(userType)}>
            <About
              title={`About ${name}`}
              description={aboutStartup}
            />
          </RenderIf>

          <RenderIf condition={!isPartner(userType)}>
            <CohortDetails />
          </RenderIf>
          <RenderIf condition={isEitherAdmin(userType)}>
            <Requests component="startup" requests={startupRequests!} />
          </RenderIf>
          <StatCards cards={isPartner(userType) ? partnersStatCards : statCards} />
          <ExpandableTable
            collapseHeader={`Team Members (${teamMembers.length})`}
            dataSource={teamMembers}
            columns={startupTeamMembersColumns}
            rowKey={getRowKey(teamMembers, 'startup-details-team-member', 'id')}
          />
          <ExpandableTable
            className="service-providers"
            collapseHeader={`Service Providers working with ${name}`}
            dataSource={startupServiceProviders}
            columns={serviceProvidersColumns}
            rowKey={getRowKey(startupServiceProviders, 'startup-details-service-provider', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default StartupDetails;
