import { AngelInvestorType as AngelInvestor, AngelInvestorStatusType, IncentiveAgreementType } from 'packages/angel_investors_repository';
import { ADGMLicenseType } from 'packages/angel_investors_repository/types/adgm_license';
import { AngelInvestorsStateType } from 'types/reduxTypes/StateTypes';
import { AngelInvestorType } from 'types/angelInvestors';
import { serviceProviderDefault } from './ServiceProvidersDefaults';
import { defaultRequestStateType } from './RequestDefaults';

const defaultStatus: AngelInvestorStatusType = {
  id: -1,
  code: '',
  name: '',
  createdAt: '',
}

export const defaultAngelInvestor: AngelInvestorType = {
  balance: '',
  consumed: '',
  raised: '',
  about: '',
  id: -1,
  imageLink: '',
  industry: '',
  country: '',
  city: '',
  name: '',
  stage: '',
  type: '',
  employees: 0,
  serviceProvidersCount: 0,
  upcomingEventsCount: 0,
  founders: [],
  status: defaultStatus,
  website: '',
  moUSigned: false,
  moUStartDate: '',
  moUEndDate: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  contactRole: '',
}

export const defaultAngelInvestorsADGMLicense: ADGMLicenseType = {
  address: '',
  expiryDate: '',
  issueDate: '',
  acceptanceDate: '',
  legalADGMName: '',
  legalSignatoryEmail: '',
  legalSignatoryName: '',
  legalSignatoryTitle: '',
  id: -1,
  aDGMLicenseNo: '',
  angelInvestorID: 0,
  aDGMLicenseUrl: '',
  approvedRejectedStatus: false,
}

export const angelInvestorDef: AngelInvestor = {
  id: -1,
  name: '',
  about: '',
  stage: -1,
  website: '',
  imageLink: '',
  location: '',
  stageName: '',
  walletID: -1,
  wallet: null,
  status: defaultStatus,
  statusId: '',
  aDGMApprovedStatus: 0,
  incentiveAgreementApprovedStatus: 0,
  currentADGMLicense: {
    aDGMLicenseUrl: '',
    aDGMLicenseNo: '',
    id: -1,
  },
  currentADGMLicenseID: 0,
  currentIncentiveAgreement: null,
  currentIncentiveAgreementID: 0,
  acceptanceLetter: '',
  acceptanceLetterApprovedStatus: 0,
  moUSigned: false,
  moUStartDate: '',
  moUEndDate: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  contactRole: '',
}

export const defaultAngelInvestorIncentiveAgreement: IncentiveAgreementType = {
  file: '',
  id: 0,
  angelInvestorID: 0,
  startDate: '',
  endDate: '',
  walletAmount: 0,
}

export const angelInvestorsDefaultState: AngelInvestorsStateType = {
  filtersActive: false,
  filters: {
    locations: [],
    stage: 0,
    capitalRaised: '',
    incentiveAmount: '',
    fieldSelection: [],
    searchString: '',
    acceptanceLetter: 0,
    incentiveAgreement: 0,
    sector: [],
  },
  incentiveAgreement: defaultAngelInvestorIncentiveAgreement,
  angelInvestors: [],
  locations: [],
  adgmLicenseByAngelInvestorId: defaultAngelInvestorsADGMLicense,
  stageOptions: [],
  angelInvestorsSectorOptions: [],
  investmentStageOptions: [],
  isLoading: false,
  details: {
    data: defaultAngelInvestor,
    requests: [],
    teamMembers: [],
    teamMembersCount: 0,
    serviceProviders: [],
    serviceProvidersCount: 0,
    serviceProvider: serviceProviderDefault,
    requestsList: [],
  },
  angelInvestorCount: 0,
  selectedAngelInvestorForReview: angelInvestorDef,
  serviceRequests: defaultRequestStateType,
};
