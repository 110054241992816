import React from 'react';
import { Form, Button } from 'antd';

import { identity, isAdmin, isSuperAdmin } from 'utils';
import {
  RenderIf, Text, Input,
} from 'components/components'
import approvedRejectedOptions from 'components/AngelInvestorsOverview/utils/constants'
import { AcceptanceLetterDetailsDrawerProps } from 'types/angelInvestors/editAngelInvestor';
import { getAngelInvestorFile } from 'components/EditAngelInvestor/utils'

import './AcceptanceLetterDetailsDrawer.scss';

const getStatusMessage = (statusId: number, name: string) => {
  switch (statusId) {
    case 0:
      return 'Waiting for Acceptance Letter.';
    case 1:
      return 'Acceptance Letter Sent. Awaiting Hub71 CEO\'s signature.';
    case 2:
      return `Acceptance Letter Sent. Awaiting ${name}'s signature.`;
    default:
      return 'Approved.';
  }
};

const AcceptanceLetterDetailsDrawer = ({
  info,
  userType,
  actions,
}:AcceptanceLetterDetailsDrawerProps) => {
  const [form] = Form.useForm();
  const statusFieldValue = Form.useWatch('status', form);
  const { id } = info;
  const statusId = info.status.id;
  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);

  const {
    updateAngelInvestorById,
    setIsDrawerOpen,
  } = actions;

  const status = getStatusMessage(statusId, info.name);

  const handleSubmit = ({
    status: statusAdgm,
    reason,
  }: {
    status: number,
    reason: string
  }) => {
    updateAngelInvestorById({
      id,
      angelInvestor: {
        acceptanceLetterApprovedStatus: statusAdgm,
        acceptanceLetterRejectReason: reason,
      },
    });
  }

  const handleCancel = () => {
    form.resetFields();
    setIsDrawerOpen(false);
  }

  const options = [
    ...approvedRejectedOptions.map((
      { title, value },
    ) => ({ label: title, value })),
  ]

  return (
    <div>
      <RenderIf condition={identity.isTruthyString(status)}>
        {isAdminOrSuperAdmin
          && statusId > 2 ? (
            <Form
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                status: info?.acceptanceLetterApprovedStatus || undefined,
                reason: '',
              }}
              className="acceptance-letter-details-drawer"
            >
              <Input
                name="status"
                label="Status"
                layout="horizontal"
              >
                <Input.Select
                  labelInValue
                  options={options}
                  placeholder={status}
                  allowClear
                />
              </Input>

              <Input.DraggerUpload
                previewType="image"
                defaultFileList={[...getAngelInvestorFile(info.acceptanceLetter!)]}
                isRoundableButton
              />

              {statusFieldValue === 2 && (
                <Input
                  name="reason"
                  label="Rejection Reason"
                  layout="vertical"
                >
                  <Input.InputField.TextArea
                    size="large"
                    rows={4}
                    placeholder="Enter Rejection Reason"
                  />
                </Input>
              )}

              <div className="drawer-buttons" style={{ marginTop: 16 }}>
                <Button
                  type="default"
                  className="secondary-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  className="primary-button"
                  onClick={form.submit}
                >
                  Save
                </Button>
              </div>
            </Form>
          ) : (
            <div className="acceptance-letter-details-drawer">
              <Text text={`Status: ${status}`} weight={400} />

              <Input.DraggerUpload
                previewType="image"
                defaultFileList={[...getAngelInvestorFile(info.acceptanceLetter!)]}
                isRoundableButton
              />
            </div>
          )}
      </RenderIf>
    </div>
  )
}

export default AcceptanceLetterDetailsDrawer;
