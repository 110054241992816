export const approvedRejectedOptions = [
  {
    value: 1,
    title: 'Approved',
    key: 'approved',
    disabled: false,
  },
  {
    value: 2,
    title: 'Rejected',
    key: 'rejected',
    disabled: false,
  },
]

export default approvedRejectedOptions;
