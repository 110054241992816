import { AddServiceProviderFieldsCreatorParams } from 'types/serviceProviders/addServiceProvider';
import { requiredValidation, emailValidation, positiveNumbersOnly } from '../../../../utils/constants/validationRules';
import { inputTypes } from '../../../components/FormBuilder/utils/constants';
import { createFormField } from '../../../components/FormBuilder/utils/FormField';
import { getFormFieldBuilder } from '../../../components/FormBuilder/utils/FormFieldBuilder';

/* Removing other types as only Incentives supported for now, and will be integrated
  with BE later to fetch them dynamically (instead of hard-coding) in the app
*/
export const SERVICE_PROVIDER_TYPES = {
  incentive: 'Incentive',
}

export const serviceProviderTypeOptions = [
  {
    label: SERVICE_PROVIDER_TYPES.incentive,
    value: SERVICE_PROVIDER_TYPES.incentive,
  },
];

export const categoryOptions = [];

export const defaultValues = {
  [SERVICE_PROVIDER_TYPES.incentive]: {
    serviceProviderType: SERVICE_PROVIDER_TYPES.incentive,
    name: '',
    role: '',
    about: '',
    email: '',
    imageLink: '',
    firstName: '',
    lastName: '',
    websiteLink: undefined,
    phoneNumber: '',
    category: '',
    serviceProviderDoc: undefined,
    recipientFirstName: '',
    recipientLastName: '',
    recipientEmail: '',
    recipientPhoneNumber: '',
    recipientRole: '',
    isAvailableForAICommunity: false,
  },
}

export const addPerksServiceFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Service Provider Name')
        .withRules([requiredValidation])
        .build(),
      createFormField('category', 'Category', inputTypes.select),
    ],
  },
  {
    fields: [
      createFormField('imageLink', 'Logo', inputTypes.image),
    ],
  },
  {
    heading: 'Contact Person',
    fields:  [
      createFormField('firstName', 'First name'),
      createFormField('lastName', 'Last name'),
    ],
  },
  {
    fields:  [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields:  [
      createFormField('role', 'Role'),
    ],
  },
];

export const addIncentiveServiceFields = ({
  handleLogoUpload,
  handleServiceDocUpload,
  handleAgreementDocUpload,
}: AddServiceProviderFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('logo', 'Logo', inputTypes.image)
        .withBeforeUpload(handleLogoUpload)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(4)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('serviceProviderDoc', 'Rate Card Document', inputTypes.dragger)
        .withBeforeUpload(handleServiceDocUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('agreementDocument', 'Agreement Document', inputTypes.dragger)
        .required()
        .withBeforeUpload(handleAgreementDocUpload)
        .build(),
    ],
  },
  {
    heading: 'Contact Person',
    fields:  [
      getFormFieldBuilder('firstName', 'First Name')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('lastName', 'Last Name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('role', 'Job Title')
        .withRules([requiredValidation])
        .required()
        .build(),
      // TODO: when be adds whatsappnumber to service provider, uncomment code and handle adding the field in the submit object and api
      // getFormFieldBuilder('whatsAppNumber', 'WhatsApp number')
      //   .withRules([requiredValidation])
      //   .required()
      //   .build(),
    ],
  },
];

export const addBothServiceFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Service Provider Name')
        .withRules([requiredValidation])
        .build(),
      createFormField('category', 'Category', inputTypes.select),
    ],
  },
  {
    fields: [
      createFormField('imageLink', 'Logo', inputTypes.image),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(4)
        .build(),
    ],
  },
  {
    heading: 'Contact Person',
    fields:  [
      createFormField('firstName', 'First name'),
      createFormField('lastName', 'Last name'),
    ],
  },
  {
    fields:  [
      createFormField('email', 'Email', inputTypes.email),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields:  [
      createFormField('role', 'Role'),
    ],
  },
];

export const addFields = {
  [SERVICE_PROVIDER_TYPES.incentive]: addIncentiveServiceFields,
}

export const addServiceProviderRecipientFields = [
  {
    heading: 'Recipient',
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email', inputTypes.email)
        .build(),
      getFormFieldBuilder('phoneNumber', 'Phone number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      createFormField('role', 'Job title'),
    ],
  },
];
