import React, { useState } from 'react'
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { requiredValidation } from 'utils';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { Input, FormBuilder, RenderIf } from 'components/components';
import { AddServiceProviderDrawerProps } from 'types/serviceProviders/addServiceProvider';
import { AddServiceProviderType } from 'types/reduxTypes/ActionTypes/ServiceProviderTypes';
import {
  defaultValues,
  serviceProviderTypeOptions,
  SERVICE_PROVIDER_TYPES,
  addServiceProviderRecipientFields,
} from './utils/constants';
import ServicesForm from './components/ServicesForm';

import './AddServiceProviderDrawer.scss';

const AddServiceProviderDrawer = (props: AddServiceProviderDrawerProps) => {
  const { actions: { addServiceProvider } } = props;

  const [form] = Form.useForm();
  const [serviceType, setServiceType] = useState<string>(SERVICE_PROVIDER_TYPES.incentive);
  const [isAvailableForAI, setIsAvailableForAI] = useState<boolean>(false);

  const handleSubmit = (data: AddServiceProviderType) => {
    const formData = form.getFieldsValue(true);
    const recipients = [
      {
        id: 0,
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        role: formData?.role,
        email: formData?.email,
        phoneNumber: formData?.phoneNumber,
      },
    ]

    const addDataValues: AddServiceProviderType = {
      serviceProviderType: data?.serviceProviderType,
      isAvailableForAICommunity: data?.isAvailableForAICommunity,
      name: data?.name,
      role: data?.role,
      about: data?.about,
      email: data?.email,
      imageLink: data?.imageLink,
      logo: data?.logo,
      firstName: data?.firstName,
      lastName: data?.lastName,
      websiteLink: data?.websiteLink,
      phoneNumber: data?.phoneNumber,
      serviceProviderDoc: data?.serviceProviderDoc,
      agreementDocument: data?.agreementDocument,
      recipients,
    };

    addServiceProvider({ data: addDataValues });
  }

  const handleUpload = (field: string, file: RcFile) => {
    form.setFieldValue(field, file as Blob);
    return false;
  }

  const handleLogoUpload = (file: RcFile) => handleUpload('logo', file);
  const handleServiceDocUpload = (file: RcFile) => handleUpload('serviceProviderDoc', file);
  const handleAgreementDocUpload = (file: RcFile) => handleUpload('agreementDocument', file);
  const handleIsAvailableForAICommunity = (data: boolean) => {
    setIsAvailableForAI(data);
  };

  return (
    <Form
      className="form-standalone"
      form={form}
      onFinish={handleSubmit}
      initialValues={defaultValues[serviceType]}
      onFinishFailed={scrollToFirstError}
    >
      <div className="input-container">
        <div className="input-field">
          <Input
            name="serviceProviderType"
            label="Service type"
            layout="vertical"
          >
            <Input.Select
              options={serviceProviderTypeOptions}
              value={serviceType}
              onChange={(value) => setServiceType(value)}
            />
          </Input>
        </div>

        <div className="input-field">
          <Input
            name="websiteLink"
            label="Website"
            layout="vertical"
          >
            <Input.InputField type="text" />
          </Input>
        </div>
      </div>

      <div className="input-container">
        <div className="input-field-row">
          <Input
            name="name"
            label="Service Provider Name"
            layout="vertical"
            required
            rules={[requiredValidation]}
          >
            <Input.InputField type="text" />
          </Input>
        </div>
      </div>

      <div className="input-field">
        <Input
          label="Available For Angel Investors"
          layout="vertical"
          name="isAvailableForAICommunity"
        >
          <Input.Switch
            onChange={handleIsAvailableForAICommunity}
            checked={isAvailableForAI}
          />
        </Input>
      </div>

      <ServicesForm
        serviceType={serviceType}
        handleLogoUpload={handleLogoUpload}
        handleServiceDocUpload={handleServiceDocUpload}
        handleAgreementDocUpload={handleAgreementDocUpload}
      />

      <RenderIf condition={false}>
        <>
          <h2 className="add-service-provider__activation-link-title">Send activation link to</h2>
          <FormBuilder
            formFragment
            fields={addServiceProviderRecipientFields}
          />
        </>
      </RenderIf>

      <DrawerButton>Add Service Provider</DrawerButton>
    </Form>
  )
}

export default AddServiceProviderDrawer;
