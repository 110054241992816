import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Spin, Button } from 'antd';
import routes from 'routes';
import { composeEditAngelInvestorUrl } from 'routes/routeComposers';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  AboutAI,
  Breadcrumb,
  RenderIf,
  Requests,
  AngelInvestorWallet,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
} from 'components/components';
import {
  identity,
  getRowKey,
  isPartner,
  getImageLink,
  isEitherAdmin,
  isAngelInvestor,
  isExpired,
} from 'utils';
import { AngelInvestorDetailsProps } from 'types/angelInvestors/angelInvestorDetails';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { serviceProvidersColumns, startupTeamMembersColumns } from '../utils/constants/columns'

import './AIDetails.scss';

const AngelInvestorDetails = (props: AngelInvestorDetailsProps) => {
  const {
    aboutAngelInvestor,
    info,
    teamMembers,
    userType,
    isLoading,
    userAngelInvestorId,
    angelInvestorServiceProviders,
    serviceRequests,
  } = props;

  const {
    getAngelInvestorById,
    getAngelInvestorMembers,
    getRequestsByOwnerId,
    getAngelInvestorServiceProviders,
    getServiceRequestByOwnerId,
    updateAngelInvestorById,
  } = props.actions
  const params: { id?: string } = useParams();
  const { id } = params;

  const {
    id: sId,
    name,
    imageLink,
    location,
    wallet,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    status,
    about,
  } = info;

  const rightContent = [
    {
      label: 'Founder',
      value: (identity.isTruthyString(contactFirstName) || identity.isTruthyString(contactLastName)) ? `${contactFirstName} ${contactLastName}` : NOT_SPECIFIED,
    },
    {
      label: 'Founder Email',
      value: identity.isTruthyString(contactEmail) ? contactEmail : NOT_SPECIFIED,
    },
    {
      label: 'Founder Contact Phone',
      value: identity.isTruthyString(contactPhone) ? contactPhone : NOT_SPECIFIED,
    },
    {
      label: 'Status',
      value: identity.isTruthyString(status?.name) ? status?.name : NOT_SPECIFIED,
    },
    {
      label: 'Description',
      value: identity.isTruthyString(about) ? about : NOT_SPECIFIED,
    },
  ]

  const socialMediaLinks = {
    facebook: info.facebook,
    instagram: info.instagram,
    twitter: info.twitter,
    linkedIn: info.linkedIn,
  };

  const angelInvestorId = identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : parseInt(id!, 10);

  const handleSetAngelInvestor = async (newStatus: number) => {
    updateAngelInvestorById({
      id,
      angelInvestor: {
        statusId: newStatus,
      },
    })
  }

  useEffect(() => {
    if (identity.isTruthyNumber(angelInvestorId)) {
      getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: true });
      getAngelInvestorMembers({ id: angelInvestorId });
      getRequestsByOwnerId({ id: angelInvestorId, owner: 'angel-investor' });
      getAngelInvestorServiceProviders({ query: { angelInvestorId, isPreload: true } });

      getServiceRequestByOwnerId({ id: angelInvestorId, owner: 'angel-investor' })
    }
  }, [angelInvestorId]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="angelinvestor-details">
          <RenderIf condition={angelInvestorId !== userAngelInvestorId}>
            <Breadcrumb navigateTo={routes.angelInvestorsUrl} routeName="Angel Investors" />
          </RenderIf>

          <RenderIf condition={isExpired(status?.code!)}>
            <StaticNotification
              message="The incentives period for this Angel investor has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>

          <TitleWithActions
            title="Angel Investor overview"
            buttonTitle="profile"
            link={
              angelInvestorId === userAngelInvestorId
                ? routes.editCompanyProfileUrl
                : composeEditAngelInvestorUrl(sId)
            }
            isEditButton
            showButton={
              isEitherAdmin(userType)
              || (isAngelInvestor(userType) && angelInvestorId === userAngelInvestorId)
            }
          >
            <RenderIf
              condition={
                isEitherAdmin(userType)
                && (status.id <= 7 || [11, 51].includes(status.id))
              }
            >
              <Button
                className="secondary-button md"
                onClick={() => handleSetAngelInvestor(status.id === 7 ? 9 : 8)}
              >
                {status.id === 7 ? 'Terminate' : 'Drop Out'}
              </Button>
            </RenderIf>
          </TitleWithActions>

          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
            userType={userType}
            type="grid"
          />

          <ExpandableTable
            collapseHeader={`Team Members (${teamMembers.length})`}
            dataSource={teamMembers}
            columns={startupTeamMembersColumns}
            rowKey={getRowKey(teamMembers, 'angelinvestor-details-team-member', 'id')}
          />

          <RenderIf condition={!isPartner(userType)}>
            <AboutAI
              title="Documents"
              isAngelInvestor={!isPartner(userType)}
              description={aboutAngelInvestor}
              angelInvestorDetails={info}
              status={status.id}
            />
          </RenderIf>

          <RenderIf condition={isPartner(userType)}>
            <AboutAI title={`About ${name}`} description={aboutAngelInvestor} />
          </RenderIf>

          <RenderIf condition={identity.isObjWithChildren(wallet) && isEitherAdmin(userType)}>
            <AngelInvestorWallet
              wallet={wallet}
              includeGraph
              showInfo
              showTransactionButton
              angelInvestorId={angelInvestorId!}
            />
          </RenderIf>

          <RenderIf condition={isEitherAdmin(userType)}>
            <Requests component="angelinvestor" requests={serviceRequests!} />
          </RenderIf>

          <ExpandableTable
            className="service-providers"
            collapseHeader={`Service Providers working with ${name}`}
            dataSource={angelInvestorServiceProviders}
            columns={serviceProvidersColumns}
            rowKey={getRowKey(angelInvestorServiceProviders, 'angelinvestor-details-service-provider', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default AngelInvestorDetails;
