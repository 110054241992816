/* eslint-disable @typescript-eslint/no-explicit-any */
import { UploadFile, UploadProps } from 'antd';
import { Rule } from 'antd/lib/form';
import { LabeledValue } from 'antd/lib/select';

import { RadioValue } from 'types/componentTypes/Input';
import FormField, { createFormField } from './FormField';

export class FormFieldBuilder {
  constructor(formField: FormField) {
    this.formField = formField;
  }

  private formField: any;

  withProps = (props: {[key: string]: any}) => {
    this.formField.componentProps = props;
    return this;
  }

  withOptions = (options: Array<LabeledValue>) => {
    this.formField.options = options;
    return this;
  };

  withRadioValues = (values: Array<RadioValue>) => {
    this.formField.values = values;
    return this;
  }

  withRules = (rules: Rule[]) => {
    this.formField.rules = rules;
    return this;
  };

  withAccept = (accept: string) => {
    this.formField.accept = accept;
    return this;
  };

  withPreviewType = (previewType: 'text' | 'image') => {
    this.formField.previewType = previewType;
    return this;
  };

  withRows = (rows: number) => {
    this.formField.rows = rows;
    return this;
  };

  disabled = (disabledCondition: boolean = true) => {
    this.formField.disabled = disabledCondition;
    return this;
  }

  withDefaultFiles = (defaultFileList: UploadFile[]) => {
    this.formField.defaultFileList = defaultFileList;
    return this;
  }

  withPlaceholder = (placeholder: string) => {
    this.formField.placeholder = placeholder;
    return this;
  }

  withBeforeUpload = (beforeUpload: UploadProps['beforeUpload']) => {
    this.formField.beforeUpload = beforeUpload;
    return this;
  }

  withOnChange = (onChange: Function) => {
    this.formField.onChange = onChange;
    return this;
  }

  required = (requiredCondition: boolean = true) => {
    this.formField.required = requiredCondition;
    return this;
  }

  min = (minValue: number) => {
    this.formField.min = minValue;
    return this;
  }

  build = () => this.formField;
}

export const getFormFieldBuilder = (name: string, label: string, type?: string) => {
  const field = createFormField(name, label, type);
  return new FormFieldBuilder(field);
}
