import { UploadProps } from 'antd';
import { LabeledValue } from 'antd/lib/select';

import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import {
  requiredValidation, emailValidation, positiveNumbersOnly, validateWebsiteURL,
} from 'utils/constants/validationRules';

export const defaultRecipients = [
  {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
  },
];

export const defaultValues = {
  industry: '',
  name: '',
  stage: '',
  website: '',
  noOfEmployees: '',
  type: '',
  cohort: '',
  description: '',
  adgmLicense: false,
  aDGMLicenseNo: '',
  logoUrl: '',
  activationLinkRecipient: defaultRecipients,
}

export type AddAIFieldsCreatorParams = {
  beforeLogoUpload: UploadProps['beforeUpload'];
}

export type AddAIDocumentsTemplatesFields = {
  emailTemplates: LabeledValue[]
  incentiveTemplates: LabeledValue[]
}

export const addAIFormFields = (
  {
    beforeLogoUpload,
  }: AddAIFieldsCreatorParams,
) => [
  {
    fields: [
      getFormFieldBuilder('logo', 'Logo', inputTypes.image)
        .withBeforeUpload(beforeLogoUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Name')
        .required()
        .build(),
      getFormFieldBuilder('website', 'Website')
        .withRules([validateWebsiteURL])
        .withPlaceholder('Enter Website URL')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withPlaceholder('Enter Description')
        .withRows(4)
        .build(),
    ],
  },
];

export const addAIFounderFields = [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter First Name')
        .required()
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Last Name')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .withPlaceholder('Enter Email')
        .required()
        .build(),
      getFormFieldBuilder('phoneNumber', 'Phone number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .withPlaceholder('Enter Phone')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('jobTitle', 'Role')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Role')
        .required()
        .build(),
    ],
  },
];

export const addAIWalletFields = [
  {
    fields: [
      getFormFieldBuilder('walletAmount', 'Wallet Amount, AED')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Wallet Amount')
        .required()
        .build(),
    ],
  },
];

export const addAIDocumentsTemplatesFields = ({
  emailTemplates,
  incentiveTemplates,
}: AddAIDocumentsTemplatesFields) => [
  {
    fields: [
      getFormFieldBuilder('acceptanceLetterTemplateId', 'Acceptance Letter Template', inputTypes.select)
        .withRules([requiredValidation])
        .withOnChange(() => {})
        .withOptions(emailTemplates)
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('incentiveAgreementTemplateId', 'Incentive Agreement Template', inputTypes.select)
        .withRules([requiredValidation])
        .withOnChange(() => {})
        .withOptions(incentiveTemplates)
        .required()
        .build(),
    ],
  },
]
