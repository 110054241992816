import { connect } from 'react-redux';

import {
  selectIsAuthenticated,
  selectIsAuthenticationChecked,
  selectUser,
  selectUserAngelInvestor,
  selectUserStartup,
  selectUserTechnicalLicense,
  selectUserType,
  selectIsBlocked,
} from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  isAuthenticated: selectIsAuthenticated(state),
  isAuthenticationChecked: selectIsAuthenticationChecked(state),
  user: selectUser(state),
  startup: selectUserStartup(state),
  angelInvestor: selectUserAngelInvestor(state),
  technicalLicense: selectUserTechnicalLicense(state),
  isBlocked: selectIsBlocked(state),
});

export default connect(mapStateToProps);
