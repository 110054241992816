import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectStageOptions,
  selectAngelInvestorFilters,
  selectSectorOptions,
} from 'redux/selectors/angelInvestors'
import {
  getAngelInvestorInvestmentStages,
  getAngelInvestorSectors,
  getAngelInvestorStages,
  setAngelInvestorFilters,
} from 'redux/actions/angelInvestors';

const mapStateToProps = (state: StateType) => ({
  stagesOptions: selectStageOptions(state),
  filters: selectAngelInvestorFilters(state),
  sectorOptions: selectSectorOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestorStages,
    getAngelInvestorSectors,
    getAngelInvestorInvestmentStages,
    setAngelInvestorFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
