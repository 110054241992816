import { ColumnsType } from 'antd/es/table';
import { LabeledValue } from 'antd/lib/select';

import { serviceProviderTypeOptions } from 'components/AddServiceProviderDrawer/utils/constants';
import { PricingOption } from '../../../../types/services';
import requiredValidation from '../../../../utils/constants/validationRules';
import { inputTypes, getFormFieldBuilder } from '../../../components/FormBuilder/utils';

export const OfferPriceValues = [
  {
    value: 'fixed',
    title: 'Fixed',
    key: 'fixed',
  },
  {
    value: 'range',
    title: 'Range',
    key: 'range',
  },
];

export const enterYourPriceMessage = 'Please enter your price';

export const OptionValues = [
  {
    value: 'per-day',
    label: 'Per day',
  },
  {
    value: 'per-week',
    label: 'Per week',
  },
  {
    value: 'per-month',
    label: 'Per month',
  },
  {
    value: 'per-hour',
    label: 'Per hour',
  },
  {
    value: 'per-year',
    label: 'Per year',
  },
];

export const defaultValues = {
  type: '',
  name: '',
  about: '',
  priceTo: '',
  priceFrom: '',
  category:[],
  offerPrice: 'fixed',
  offerDocLink: '',
  additionalOptionName: '',
  additionalOptionPrice: '',
  isAvailableForAICommunity: false,
  pricingOptions: [
    {
      id: 0,
      amount: '',
      option: 'per-month',
    },
  ],
}

export const ServiceDetailFormFields = [
  {
    fields: [
      getFormFieldBuilder('type', 'Service type', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviderTypeOptions)
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Service name')
        .withRules([requiredValidation])
        .withPlaceholder('eg. UX Design')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(5)
        .withPlaceholder('write something...')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('offerPrice', 'Offer Price', inputTypes.radio)
        .withRadioValues(OfferPriceValues)
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export const setErrorForAmounts = (pricingOptions: PricingOption[]) => {
  const newErrors = pricingOptions.map((option) => option.amount.length < 1);
  const submit = newErrors.some((error) => error);

  return { submit, newErrors };
}

export const subCategoryColumns: ColumnsType<LabeledValue> = [
  {
    key: 'name',
    title: 'Sub Category',
    dataIndex: 'name',
  },
];

export const resetFiltersEvent = 'service-form-reset';

export type ServiceDetailsParams = {
  serviceProviders: LabeledValue[],
  serviceProviderType: LabeledValue[],
  offerPriceOptions: { value: string, key: string, title: string}[],
}

export const AdminServiceDetailFormFields = ({ serviceProviders, serviceProviderType }: ServiceDetailsParams) => [
  {
    fields: [
      getFormFieldBuilder('serviceProviderId', 'Service provider', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviders)
        .required()
        .build(),
      getFormFieldBuilder('type', 'Service type', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviderType)
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Service name')
        .withRules([requiredValidation])
        .withPlaceholder('eg. UX Design')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(5)
        .withPlaceholder('write something...')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('offerPrice', 'Offer Price', inputTypes.radio)
        .withRadioValues(OfferPriceValues)
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
