import React from 'react';

import { Navbar } from '../../../LayoutWithSidebar/components'
import StaticNotification from '../../../components/Notification/StaticNotification'

export const Forbidden = () => (
  <div>
    <Navbar />
    <p
      style={{
        padding: 50,
        textAlign: 'center',
      }}
    >
      <StaticNotification
        type="warning"
        message="Your account has been deactivated. If you believe this is an error, please contact the Hub71 admin for assistance."
      />
    </p>
  </div>
)

export default Forbidden;
