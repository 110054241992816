import React from 'react';

import { items, itemsMap, selectedKey } from '../../utils/constants';
import Input from '../../../../../components/Input';
import { NavbarButtonsProps } from '../../../../../../types/reduxTypes/ActionTypes/WorkspaceTypes';
import identity from '../../../../../../utils/identity';
import AddNew from '../AddNew';

import '../../Navbar.scss';

const DropdownComp = (props: NavbarButtonsProps) => {
  const {
    userType, title, isfulfilled, actions,
  } = props;

  type MenuInfo = {
    key: string;
    keyPath: string[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  }

  const handleClick = (item: MenuInfo) => {
    const drawerBody = itemsMap[parseInt(item.key, 10) - 1].drawer;
    if (identity.isTruthyString(drawerBody)) {
      actions.setDrawerBody({ drawerBody });
      actions.setIsDrawerOpen({ isDrawerOpen: true });
    }
  }

  const itemsCount = items[userType]?.length;

  if (!identity.isTruthyNumber(itemsCount)) {
    return null;
  }

  if (itemsCount === 1) {
    return <AddNew item={items[userType][0]} />
  }

  const menuProps = {
    selectable: true,
    defaultSelectedKeys: [selectedKey],
    items: items[userType],
    onClick: handleClick,
    className: 'create-new-dropdown',
  };

  return (
    <Input className="navbar-dropdowns" layout="vertical">
      <Input.Dropdown
        menu={isfulfilled ? undefined : menuProps}
        className={isfulfilled ? 'secondary-button' : 'primary-button'}
      >
        {title}
      </Input.Dropdown>
    </Input>
  );
}

export default DropdownComp;
