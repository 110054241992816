import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';

import { setIsDrawerOpen } from 'redux/actions/workspace';
import {
  updateAngelInvestorById,
} from 'redux/actions/angelInvestors';

import {
  selectAngelInvestorDetails,
} from 'redux/selectors/angelInvestors';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  info: selectAngelInvestorDetails(state),
  userType: selectUserType(state),
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateAngelInvestorById,
    setIsDrawerOpen,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
