import React from 'react';

import Input from 'components/components/Input';
import { PasswordInputProps } from 'types/componentTypes/ConfirmPassword';

import './PasswordField.scss';

const PasswordField = (props: PasswordInputProps) => (
  <div className="password-input-container">
    <Input
      label={props.label}
      name={props.name}
      required
      layout="vertical"
      labelCol={{ span: 24 }}
      rules={props.rules}
      validateFirst
    >
      <Input.Password
        type="password"
        value={props.value}
        onChange={props.onChange}
      />
    </Input>
  </div>
);

export default PasswordField;
