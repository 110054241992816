import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';

import httpClient from 'packages/http_client';

import { types as cohortTypes } from '../actions/cohort';
import { types as serviceProviderTypes } from '../actions/serviceProviders';
import { types as startupTypes } from '../actions/startups';
import { types as serviceTypes } from '../actions/services';
import { types as peopleTypes } from '../actions/people';
import { types as requestTypes } from '../actions/request';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const driver = createDriver(httpClient);

const { requestsMiddleware } = handleRequests({
  driver,
  cache: true,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        cohortTypes.CREATE_COHORT,
        serviceProviderTypes.ADD_SERVICE_PROVIDER,
        startupTypes.CREATE_STARTUP,
        serviceTypes.ADD_SERVICE,
        peopleTypes.UPDATE_MEMBER_BY_ID,
        requestTypes.ADD_NEW_REQUEST,
        startupTypes.SET_ADGM_LICENSE_BY_STARTUP_ID,
        startupTypes.UPDATE_ADGM_LICENSE_BY_STARTUP_ID,
        startupTypes.CREATE_STARTUP_ADGM_LICENSE,
      ],
    },
  }).prepend(requestsMiddleware)
    .concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
